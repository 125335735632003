import SectionHeader from "../common/SectionHeader";

const ClvDatabase = () => {
  return (
    <>
      <SectionHeader title="Clv Database" />
    </>
  );
};

export default ClvDatabase;
