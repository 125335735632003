import React from "react";
import { Table, Thead, Tbody, Tr, Td } from "@chakra-ui/react";
import classes from "./Table.module.css";
import { TableHeadColumn } from "../../../HumanResource/Approvals/ApprovalTable/ApprovedTable";

const travelOrderData1 = [
  {
    stage: "1",
    userName: "Fleet Manager Department",
    designation: "Fleet manager",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];

const activityRequisitionData1 = [
  {
    stage: "1",
    userName: "Programs Manager",
    designation: "programsManager",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  // {
  //   stage: "2",
  //   userName: "Project Accountant ",
  //   designation: "Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  {
    stage: "2",
    userName: "Director of Finance",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
const activityRequisitionData2 = [

  {
    stage: "2",
    userName: "Director of Finance",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
const activityRequisitionData3 = [
  {
    stage: "3",
    userName: "Director of Finance",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "4",
    userName: "Senior Accountant",
    designation: "Senior Accountant",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
const activityRequisitionData4 = [
  {
    stage: "4",
    userName: "Senior Accountant",
    designation: "Senior Accountant",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];

const adminRequisitionData1 = [
  // {
  //   stage: "1",
  //   userName: "Project Accountant ",
  //   designation: "Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  {
    stage: "1",
    userName: "Director of Finance",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "2",
    userName: "Procurement officer",
    designation: "procurement",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];

const adminRequisitionData2 = [
  {
    stage: "1",
    userName: "Procurement officer",
    designation: "procurement",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  // {
  //   stage: "2",
  //   userName: "Director of Finance",
  //   designation: "Finance",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  // {
  //   stage: "3",
  //   userName: "Senior Accountant",
  //   designation: "Senior Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
];

// const adminRequisitionData3 = [
//   {
//     stage: "3",
//     userName: "Senior Accountant",
//     designation: "Senior Accountant",
//     status: "Pending",
//     remarks: "No remarks",
//     date: "No date",
//   },
// ];

const procurementRequisitionData1 = [
  // {
  //   stage: "1",
  //   userName: "Dop",
  //   designation: "Dop",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
   {
    stage: "1",
    userName: "Programs Manager",
    designation: "programsManager",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "2",
    userName: "Finance Manager",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "3",
    userName: "Chairperson Procurement",
    designation: "procurement",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  // {
  //   stage: "3",
  //   userName: "Senior Accountant",
  //   designation: "Senior Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
];
const procurementRequisitionData2 = [
  // {
  //   stage: "2",
  //   userName: "Project Accountant ",
  //   designation: "Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  // {
  //   stage: "3",
  //   userName: "Director of Finance",
  //   designation: "Finance",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  {
    stage: "2",
    userName: "Finance Manager",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "3",
    userName: "Chairperson Procurement",
    designation: "procurement",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  // {
  //   stage: "3",
  //   userName: "Senior Accountant",
  //   designation: "Senior Accountant",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
];
const procurementRequisitionData3 = [
  // {
  //   stage: "3",
  //   userName: "Director of Finance",
  //   designation: "Finance",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  // {
  //   stage: "4",
  //   userName: "Procurement officer",
  //   designation: "procurement",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  {
    stage: "3",
    userName: "Chairperson Procurement",
    designation: "procurement",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
// const procurementRequisitionData4 = [
//   {
//     stage: "4",
//     userName: "Procurement officer",
//     designation: "procurement",
//     status: "Pending",
//     remarks: "No remarks",
//     date: "No date",
//   },
//   {
//     stage: "5",
//     userName: "Senior Accountant",
//     designation: "Senior Accountant",
//     status: "Pending",
//     remarks: "No remarks",
//     date: "No date",
//   },
// ];
// const procurementRequisitionData5 = [
//   {
//     stage: "5",
//     userName: "Senior Accountant",
//     designation: "Senior Accountant",
//     status: "Pending",
//     remarks: "No remarks",
//     date: "No date",
//   },
// ];

const leaveData1 = [
  // {
  //   stage: "1",
  //   userName: "DOP Department",
  //   designation: "Dop",
  //   status: "Pending",
  //   remarks: "No remarks",
  //   date: "No date",
  // },
  {
    stage: "1",
    userName: "Human Resources Department",
    designation: "Human Resources",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
// const leaveData2 = [
//   {
//     stage: "2",
//     userName: "Human Resources Department",
//     designation: "Human Resources",
//     status: "Pending",
//     remarks: "No remarks",
//     date: "No date",
//   },
// ];

const advanceData1 = [
  {
    stage: "1",
    userName: "Human Resources",
    designation: "Human Resources",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "2",
    userName: "Ceo Department",
    designation: "Ceo",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "3",
    userName: "Finance Department",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
const advanceData2 = [
  {
    stage: "3",
    userName: "Ceo Department",
    designation: "Ceo",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
  {
    stage: "4",
    userName: "Finance Department",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];
const advanceData3 = [
  {
    stage: "3",
    userName: "Finance Department",
    designation: "Finance",
    status: "Pending",
    remarks: "No remarks",
    date: "No date",
  },
];

const SummaryTable = ({ data }) => {
  // console.log(data);

  return (
    <>
      <div className={classes.table_container}>
        <Table size="sm" variant="simple" className={classes.table__wrapper}>
          <Thead className={classes.table_header}>
            <Tr>
              <TableHeadColumn title="Stage" />
              <TableHeadColumn title="Username" />
              <TableHeadColumn title="Designation" />
              <TableHeadColumn title="Status" />
              <TableHeadColumn title="Remarks" />
              <TableHeadColumn title="Date of Action taken" />
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.travelOrder &&
              data.travelOrder.approval_levels &&
              data.travelOrder.approval_levels.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.username}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{new Date(item.date).toLocaleString()}</Td>
                  </Tr>
                );
              })}

            {data &&
              data.requisition &&
              data.requisition.approval_levels &&
              data.requisition.approval_levels.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.username}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{new Date(item.date).toLocaleString()}</Td>
                  </Tr>
                );
              })}

            {data &&
              data.leave &&
              data.leave.approval_levels &&
              data.leave.approval_levels.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.username}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{new Date(item.date).toLocaleString()}</Td>
                  </Tr>
                );
              })}

            {data &&
              data.advance &&
              data.advance.approval_levels &&
              data.advance.approval_levels.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.username}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{new Date(item.date).toLocaleString()}</Td>
                  </Tr>
                );
              })}

            {/*check for travel order remarks*/}
            {data?.travelOrder?.approval_levels?.length === 0 &&
              travelOrderData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}

            {/*check for activity requisition request remarks*/}
            {data?.requisition?.approval_levels?.length === 0 &&
              data?.requisition?.type === "activity" &&
              activityRequisitionData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.requisition?.approval_levels?.length === 1 &&
              data?.requisition?.type === "activity" &&
              activityRequisitionData2.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {/* {data?.requisition?.approval_levels?.length === 2 &&
              data?.requisition?.type === "activity" &&
              activityRequisitionData3.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}
            {/* {data?.requisition?.approval_levels?.length === 3 &&
              data?.requisition?.type === "activity" &&
              activityRequisitionData4.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}

            {/*check for admin requisition request remarks*/}
            {data?.requisition?.approval_levels?.length === 0 &&
              data?.requisition?.type === "admin" &&
              adminRequisitionData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.requisition?.approval_levels?.length === 1 &&
              data?.requisition?.type === "admin" &&
              adminRequisitionData2.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {/* {data?.requisition?.approval_levels?.length === 2 &&
              data?.requisition?.type === "admin" &&
              adminRequisitionData3.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}

            {/*check for procurement requisition request remarks*/}
            {data?.requisition?.approval_levels?.length === 0 &&
              data?.requisition?.type === "procurement" &&
              procurementRequisitionData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.requisition?.approval_levels?.length === 1 &&
              data?.requisition?.type === "procurement" &&
              procurementRequisitionData2.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.requisition?.approval_levels?.length === 2 &&
              data?.requisition?.type === "procurement" &&
              procurementRequisitionData3.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {/* {data?.requisition?.approval_levels?.length === 3 &&
              data?.requisition?.type === "procurement" &&
              procurementRequisitionData4.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}
            {/* {data?.requisition?.approval_levels?.length === 4 &&
              data?.requisition?.type === "procurement" &&
              procurementRequisitionData5.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}

            {/*check for leave request remarks*/}
            {data?.leave?.approval_levels?.length === 0 &&
              leaveData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {/* {data?.leave?.approval_levels?.length === 1 &&
              leaveData2.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })} */}

            {/*check for advance request remarks*/}
            {data?.advance?.approval_levels?.length === 0 &&
              advanceData1.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.advance?.approval_levels?.length === 1 &&
              advanceData2.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
            {data?.advance?.approval_levels?.length === 2 &&
              advanceData3.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td> {item.stage}</Td>
                    <Td>{item.userName}</Td>
                    <Td>{item.designation}</Td>
                    <Td>{item.status}</Td>
                    <Td>{item.remarks}</Td>
                    <Td>{item.date}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </div>
    </>
  );
};

export default SummaryTable;
