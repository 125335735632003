import React, { useState } from "react";
import classes from "./SummaryDetails.module.css";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { SimpleGrid, Textarea, Button } from "@chakra-ui/react";
import SummaryTable from "./SummaryTable/SummaryTable";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useAdvance,
  useApproveAdvance,
  useRejectAdvance,
} from "../../../hooks/useAdvances";
import {
  useApproveLeaveRequest,
  useLeaveRequest,
  useRejectLeaveRequest,
} from "../../../hooks/useLeaveRequest";
import {
  useApproveTravelOrder,
  useRejectTravelOrder,
  useTravelOrder,
} from "../../../hooks/useTravelOrders";
import {
  useApproveRequisition,
  useRejectRequisition,
  useRequisition,
} from "../../../hooks/useRequisitions";
import Loader from "../../common/UI/Loader/Loader";

import { useSelector } from "react-redux";
import { useProject } from "../../../hooks/useProjects";
import ActivityTable from "../../forms/requisition/Activity/ActivityTable";
import { RiFile2Fill } from "react-icons/ri";

const SummaryDetails = (props) => {
  const { type } = props;
  const navigate = useNavigate();
  const [disableReject, setDisableReject] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);

  const [remarks, setRemarks] = useState("");

  const { mutate: approveReq } = useApproveRequisition();
  const { mutate: rejectReq } = useRejectRequisition();

  const { mutate: approveLeaveReq } = useApproveLeaveRequest();
  const { mutate: rejectLeaveReq } = useRejectLeaveRequest();

  const { mutate: approveAdv } = useApproveAdvance();
  const { mutate: rejectAdv } = useRejectAdvance();

  const { mutate: approveTravel } = useApproveTravelOrder();
  const { mutate: rejectTravel } = useRejectTravelOrder();

  //get user and designation
  const { user } = useSelector((state) => state.auth);

  //====GET THE SELECTED DOCUMENT CATEGORY====//
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const selectedType = query.get("application-type");
  const id = query.get("id");

  const { travelName } = useParams();

  const { reqName } = useParams();

  const { data, isLoading } = useAdvance(id);

  const { data: leaveData, isLoading: loadingLeaveRequests } =
    useLeaveRequest(id);

  const { data: travelData, isLoading: loadingTravelOrders } =
    useTravelOrder(travelName);

  const projectName = useProject(travelData?.travelOrder?.project);

  const { data: reqData, isLoading: loadingReqData } = useRequisition(reqName);
  const reqProjectName = useProject(reqData?.requisition?.project_name);

  const approveTravelOrder = (e) => {
    e.preventDefault();
    setDisableApprove(true);
    approveTravel({ travelName, remarks });
  };

  const rejectTravelOrder = (e) => {
    e.preventDefault();
    setDisableReject(true);
    rejectTravel({ travelName, remarks });
  };

  const approveRequisition = (e) => {
    e.preventDefault();
    setDisableApprove(true);
    approveReq({ reqName, remarks });
  };

  const rejectRequisition = (e) => {
    e.preventDefault();
    setDisableReject(true);
    rejectReq({ reqName, remarks });
  };

  const approveLeave = (e) => {
    e.preventDefault();
    setDisableApprove(true);
    approveLeaveReq({ id, remarks });
  };

  const rejectLeave = (e) => {
    e.preventDefault();
    setDisableReject(true);
    rejectLeaveReq({ id, remarks });
  };

  const approveAdvance = (e) => {
    e.preventDefault();
    setDisableApprove(true);
    approveAdv({ id, remarks });
  };
  const rejectAdvance = (e) => {
    e.preventDefault();
    setDisableReject(true);
    rejectAdv({ id, remarks });
  };

  return (
    <div className={classes.summary_wrapper}>
      <div className={classes.summary_header}>
        <MdOutlineArrowBackIosNew
          onClick={() =>
            navigate(
              `${
                type === "requisition"
                  ? "/requisitions"
                  : type === "travel"
                  ? "/travel-order"
                  : "/leave-advance-tracker"
              }`
            )
          }
        />
        <h1>
          {type === "requisition"
            ? "Requisition Summary"
            : type === "travel"
            ? "Travel Order Summary"
            : ` ${selectedType === "leave" ? "Leave" : "Advance"} Application`}
        </h1>
      </div>
      <div className={classes.summary_details_wrapper}>
        <div className={classes.summary_wrapper_inner}>
          <h2>
            {type === "requisition"
              ? "Requisition Summary"
              : type === "travel"
              ? "Travel Order Summary"
              : `${
                  selectedType === "leave" ? "Leave" : "Advance"
                } Aplication Form Summary`}
          </h2>

          <div className={classes.user_details}>
            {isLoading && selectedType === "advance" ? (
              <Loader />
            ) : (
              !isLoading &&
              data &&
              selectedType !== "leave" && (
                <SimpleGrid columns={2} spacing={1}>
                  <h6>Date of Application:</h6>
                  <h5>{new Date(data?.advance?.createdAt).toLocaleString()}</h5>
                  <h6>Name:</h6>
                  <h5>{data?.advance?.user?.full_name}</h5>
                  <h6>Designation:</h6>
                  <h5>{data?.advance?.user?.designation}</h5>
                  <h6>Budget Year:</h6>
                  <h5>{data?.advance?.budget_year}</h5>
                  <h6>Month for Application:</h6>
                  <h5>{data?.advance?.month}</h5>
                  <h6>Net Salary:</h6>
                  <h5>{data?.advance?.net_pay}</h5>
                  <h6>Advance:</h6>
                  <h5>{data?.advance?.amount}</h5>
                  <h6>Reason:</h6>
                  <h5>{data?.advance?.reason}</h5>
                </SimpleGrid>
              )
            )}
            {loadingReqData && type === "requisition" ? (
              <Loader />
            ) : (
              !loadingReqData &&
              reqName &&
              type === "requisition" && (
                <>
                  <SimpleGrid columns={2} spacing={1}>
                    <h6>Project Name:</h6>
                    <h5>{reqProjectName?.data?.project?.name}</h5>
                    <h6>Budget year </h6>
                    <h5>{reqData?.requisition?.budget_year}</h5>
                    <h6>Type: </h6>
                    <h5>{reqData?.requisition?.type}</h5>
                    {reqData?.requisition?.type !== "activity" && (
                      <>
                        <h6>Unit Price:</h6>
                        <h5>{reqData?.requisition?.unit_price}</h5>
                        <h6>Number of Units:</h6>
                        <h5>{reqData?.requisition?.num_units}</h5>
                        <h6>Subject of Procurement:</h6>
                        <h5>{reqData?.requisition?.subject_of_procurement}</h5>
                      </>
                    )}
                            {
                        reqData?.requisition?.attachmentKeys.length >0?
                        <> 
                            <h6>Attachments</h6>
                        
                        {reqData?.requisition?.attachmentKeys?.map((fileName, index) => (
                          <>
                            <h5 className={classes.file_name}>{fileName.length > 15 ? fileName.substring(0, 40) + '...' : fileName} <a
                              href={reqData?.requisition?.attachmentDownloadLinks[index]}
                              download={fileName}
                              className={classes.download_link}
                            >
                              Download
                            </a></h5>
                           
                          </>
                        ))}</>:
                        <h6>No files attached</h6>
                      }
                  </SimpleGrid>
                  {reqData?.requisition?.type === "activity" && (
                    <div>
                      <h6>Activity items</h6>
                      <div
                        className={classes.table_wrapper}
                        style={{
                          width: "100%",
                        }}
                      >
                        <ActivityTable
                          hideActions
                          data={reqData?.requisition?.activities.map((item) => {
                            return { ...item, id: item.id };
                          })}
                        />
                      </div>
                      <br />
                    </div>
                  )}
                  <SimpleGrid columns={2} spacing={1}>
                    <h6>Date required:</h6>
                    <h5>
                      {new Date(
                        reqData?.requisition?.date_required
                      ).toLocaleString()}
                    </h5>
                    <h6>Delivery Location:</h6>
                    <h5>{reqData?.requisition?.delivery_location}</h5>
                  </SimpleGrid>
                </>
              )
            )}

            {loadingTravelOrders && type === "travel" ? (
              <Loader />
            ) : (
              !loadingTravelOrders &&
              travelData &&
              type === "travel" && (
                <SimpleGrid columns={2} spacing={1}>
                  <h6>Date requested:</h6>
                  <h5>
                    {new Date(
                      travelData?.travelOrder?.createdAt
                    ).toLocaleString()}
                  </h5>
                  <h6>Journey start date & time:</h6>
                  <h5>
                    {new Date(
                      travelData?.travelOrder?.journey_start_time
                    ).toLocaleString()}
                  </h5>
                  <h6>Journey end date & time: </h6>
                  <h5>
                    {new Date(
                      travelData?.travelOrder?.journey_end_time
                    ).toLocaleString()}
                  </h5>
                  <h6>Pick up location:</h6>
                  <h5>{travelData?.travelOrder?.pickup_location}</h5>
                  <h6>Destination:</h6>
                  <h5>{travelData?.travelOrder?.destination}</h5>
                  <h6>Project:</h6>
                  <h5>{projectName?.data?.project?.name}</h5>
                  <h6>Project Activity:</h6>
                  <h5>{travelData?.travelOrder?.project_activity}</h5>
                  <h6>Purpose of Activity:</h6>
                  <h5>{travelData?.travelOrder?.purpose}</h5>
                </SimpleGrid>
              )
            )}

            {loadingLeaveRequests && selectedType === "leave" ? (
              <Loader />
            ) : (
              !loadingLeaveRequests &&
              leaveData &&
              selectedType === "leave" && (
                <SimpleGrid columns={2} spacing={1}>
                  <h6>Date of Application:</h6>
                  <h5>
                    {new Date(leaveData?.leave?.updateAt).toLocaleString()}
                  </h5>
                  <h6>Name:</h6>
                  <h5>{leaveData?.leave?.user?.full_name}</h5>
                  <h6>Designation:</h6>
                  <h5>{leaveData?.leave?.user?.designation}</h5>
                  <h6>I wish to apply for:</h6>
                  <h5>{leaveData?.leave?.duration_type}</h5>
                  <h6>Reason:</h6>
                  <h5>{leaveData?.leave?.reason}</h5>
                  <h6>Details:</h6>
                  <h5>{leaveData?.leave?.details}</h5>
                  <h6>While on leave my physical contact will be:</h6>
                  <h5>{leaveData?.leave?.address_on_leave}</h5>
                  <h6>Tel:</h6>
                  <h5>{leaveData?.leave?.tel_on_leave}</h5>
                  <h6>Person in charge:</h6>
                  <h5>{leaveData?.leave?.in_charge?.full_name}</h5>
                </SimpleGrid>
              )
            )}
          </div>
        </div>

        {type === "travel" &&
          (user.designation === "dop" ||
            user.designation === "finance" ||
            user.designation === "accountant" ||
            user.designation === "fleetManager" ||
            user.designation === "supervisor" ||
            user.designation === "humanResources" ||
            user.designation === "procurement" ||
            user.designation === "ceo") && (
            <div className={classes.table_wrapper}>
              <h6>Travel Progress</h6>
              <SummaryTable
                data={
                  type === "requisition"
                    ? reqData
                    : type === "travel"
                    ? travelData
                    : selectedType === "leave"
                    ? leaveData
                    : selectedType !== "leave"
                    ? data
                    : travelData
                }
              />

              {/*fleetManager Approval- level 1*/}
              {travelData?.travelOrder?.approval_levels?.length === 0 &&
                user.designation === "fleetManager" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectTravelOrder}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveTravelOrder}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
            </div>
          )}

        {/*normal users*/}
        {(type === "travel" ||
          type === "requisition" ||
          selectedType === "leave" ||
          selectedType === "advance") &&
          user.designation !== "programsManager" &&
          user.designation !== "humanResources" &&
          user.designation !== "accountant" &&
          user.designation !== "finance" &&
          user.designation !== "fleetManager" &&
          user.designation !== "ceo" &&
          user.designation !== "seniorAccountant" &&
          user.designation !== "procurement" &&
          user.designation !== "supervisor" && (
            <div className={classes.table_wrapper}>
              <h6>
                {type} {selectedType} Progress
              </h6>
              <SummaryTable
                data={
                  type === "requisition"
                    ? reqData
                    : type === "travel"
                    ? travelData
                    : selectedType === "leave"
                    ? leaveData
                    : selectedType !== "leave"
                    ? data
                    : travelData
                }
              />
            </div>
          )}

        {/*approval level for requisitions*/}
        {type === "requisition" &&
          (
            user.designation === "programsManager" ||
            user.designation === "finance" ||
            user.designation === "accountant" ||
            user.designation === "procurement" ||
            user.designation === "fleetManager" ||
            user.designation === "humanResources" ||
            user.designation === "supervisor" ||
            user.designation === "seniorAccountant" ||
            user.designation === "ceo") && (
            <div className={classes.table_wrapper}>
              <h6>Requisition Progress</h6>
              <SummaryTable
                data={
                  type === "requisition"
                    ? reqData
                    : type === "travel"
                    ? travelData
                    : selectedType === "leave"
                    ? leaveData
                    : selectedType !== "leave"
                    ? data
                    : travelData
                }
              />

              {/* Approval for Activity requisitions*/}
              {/*Dop Approval- level 1*/}
              {reqData?.requisition?.approval_levels?.length === 0 &&
                reqData?.requisition?.type === "activity" &&
                user.designation === "programsManager" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
              {/* Project Accountant Approval- level 2*/}
              {/* {reqData?.requisition?.approval_levels?.length === 1 &&
                reqData?.requisition?.type === "activity" &&
                reqData?.requisition?.approval_levels?.[0]?.status ===
                  "approved" &&
                user.designation === "accountant" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )} */}

              {/*finance approval - level 3*/}
              {reqData?.requisition?.approval_levels?.length === 1 &&
                reqData?.requisition?.type === "activity" &&
                reqData?.requisition?.approval_levels?.[0]?.status ===
                  "approved" &&
                user.designation === "finance" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*Senior Accountant approval - level 4*/}
              {reqData?.requisition?.approval_levels?.length === 2 &&
                reqData?.requisition?.type === "activity" &&
                reqData?.requisition?.approval_levels?.[1]?.status ===
                  "approved" &&
                user.designation === "seniorAccountant" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*approval level for admin requisitions*/}
              {/*Accountant Approval- level 1*/}
              {reqData?.requisition?.approval_levels?.length === 0 &&
                reqData?.requisition?.type === "admin" &&
                user.designation === "accountant" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
              {/* head of finance Approval- level 2*/}
              {reqData?.requisition?.approval_levels?.length === 0 &&
                reqData?.requisition?.type === "admin" &&
                // reqData?.requisition?.approval_levels?.[0]?.status ===
                //   "approved" &&
                user.designation === "finance" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*senior accountant approval - level 3*/}
              {reqData?.requisition?.approval_levels?.length === 1 &&
                reqData?.requisition?.type === "admin" &&
                reqData?.requisition?.approval_levels?.[0]?.status ===
                  "approved" &&
                user.designation === "seniorAccountant" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*approval level for procurement requisitions*/}
              {/*Dop Approval- level 1*/}
              {/* {reqData?.requisition?.approval_levels?.length === 0 &&
                reqData?.requisition?.type === "procurement" &&
                user.designation === "dop" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )} */}
              {/* Project Accountant Approval- level 2*/}
              {reqData?.requisition?.approval_levels?.length === 0 &&
                reqData?.requisition?.type === "procurement" &&
                // reqData?.requisition?.approval_levels?.[0]?.status ===
                //   "approved" &&
                user.designation === "programsManager" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*finance approval - level 3*/}
              {reqData?.requisition?.approval_levels?.length === 1 &&
                reqData?.requisition?.type === "procurement" &&
                reqData?.requisition?.approval_levels?.[0]?.status ===
                  "approved" &&
                user.designation === "finance" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*Procurement approval - level 4*/}
              {reqData?.requisition?.approval_levels?.length === 2 &&
                reqData?.requisition?.type === "procurement" &&
                reqData?.requisition?.approval_levels?.[1]?.status ===
                  "approved" &&
                user.designation === "procurement" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*Senior Accountant approval - level 5*/}
              {reqData?.requisition?.approval_levels?.length === 3 &&
                reqData?.requisition?.type === "procurement" &&
                reqData?.requisition?.approval_levels?.[2]?.status ===
                  "approved" &&
                user.designation === "seniorAccountant" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectRequisition}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveRequisition}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
            </div>
          )}
{/* procuement requisition ends here */}
        {selectedType === "leave" &&
          (
            // user.designation === "dop" ||
            user.designation === "finance" ||
            user.designation === "supervisor" ||
            user.designation === "humanResources" ||
            user.designation === "procurement" ||
            user.designation === "fleetManager" ||
            user.designation === "accountant" ||
            user.designation === "ceo") && (
            <div className={classes.table_wrapper}>
              <h6>Leave Progress</h6>
              <SummaryTable
                data={
                  type === "requisition"
                    ? reqData
                    : type === "travel"
                    ? travelData
                    : selectedType === "leave"
                    ? leaveData
                    : selectedType !== "leave"
                    ? data
                    : travelData
                }
              />

              {/*dop Approval- level 1*/}
              {/* {leaveData?.leave?.approval_levels?.length === 0 &&
                user.designation === "dop" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectLeave}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveLeave}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )} */}
              {/*humanResources approval - level 2*/}
              {leaveData?.leave?.approval_levels?.length === 0 &&
                // leaveData?.leave?.approval_levels?.[0]?.status === "approved" &&
                user.designation === "humanResources" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectLeave}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveLeave}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
            </div>
          )}

        {selectedType === "advance" &&
          (user.designation === "dop" ||
            user.designation === "accountant" ||
            user.designation === "ceo" ||
            user.designation === "fleetManager" ||
            user.designation === "procurement" ||
            user.designation === "humanResources" ||
            user.designation === "supervisor" ||
            user.designation === "finance") && (
            <div className={classes.table_wrapper}>
              <h6>Advance Progress</h6>
              <SummaryTable
                data={
                  type === "requisition"
                    ? reqData
                    : type === "travel"
                    ? travelData
                    : selectedType === "leave"
                    ? leaveData
                    : selectedType !== "leave"
                    ? data
                    : travelData
                }
              />

              {/*HR Approval- level 1*/}
              {data?.advance?.approval_levels?.length === 0 &&
                user.designation === "humanResources" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectAdvance}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveAdvance}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}

              {/*Ceo approval - level 2*/}
              {data?.advance?.approval_levels?.length === 1 &&
                data?.advance?.approval_levels?.[0]?.status === "approved" &&
                user.designation === "ceo" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectAdvance}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveAdvance}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
              {/*Finance approval - level 3*/}
              {data?.advance?.approval_levels?.length === 2 &&
                data?.advance?.approval_levels?.[1]?.status === "approved" &&
                user.designation === "finance" && (
                  <form>
                    <div className={classes.remarks_wrapper}>
                      <h6>
                        <strong>Remarks</strong>
                      </h6>
                      <Textarea
                        placeholder="Leave a remark here"
                        name="remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                    <hr />
                    <div className={classes.form_action_wrapper}>
                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#ff3838"
                        _hover={{ color: "white", background: "#ff3838" }}
                        size="lg"
                        color="black"
                        borderColor="#ff3838"
                        isLoading={disableReject}
                        loadingText="Rejecting"
                        onClick={rejectAdvance}
                      >
                        Reject
                      </Button>

                      <Button
                        variant="outline"
                        type="submit"
                        borderRadius="full"
                        colorScheme="#562b85"
                        _hover={{ color: "white", background: "#562b85" }}
                        size="lg"
                        color="black"
                        borderColor="#562b85"
                        isLoading={disableApprove}
                        loadingText="Approving"
                        onClick={approveAdvance}
                      >
                        Approve
                      </Button>
                    </div>
                  </form>
                )}
            </div>
          )}
      </div>
    </div>
  );
};

export default SummaryDetails;
