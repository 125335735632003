// export const BASE_URL = "https://fida-codebits.onrender.com/api/v1";
export const BASE_URL = "https://api2.fidauganda.or.ug/api/v1";
// export const BASE_URL = "http://localhost:3000/api/v1"
export const TEN_MBS_IN_BYTES = 10485760;

export const USERS_KEY = "users";
export const CLIENTS_KEY = "clients";
export const CASES_KEY = "cases";
export const REQUISITIONS_KEY = "requisitions";
export const TRAVEL_ORDER_KEY = "travel_orders";
export const TASKS_KEY = "tasks";
export const COMMENTS_KEY = "comments";
export const CLVS_KEY = "clvs";
export const DATABASE_KEY = "database";
export const COMPLAINTS_KEY = "complaints";
export const PROJECTS_KEY = "projects";
export const ASSETS_KEY = "assets";
export const MEMBERSHIP_KEY = "membership";
export const MEMBERSHIP_ACTIVITIES_KEY = "membership_activities";
export const NOTIFICATION_KEY = "notifications";
export const LEAVE_TRACKER_KEY = "leave_tracker";
export const LEAVE_REQUESTS_KEY = "leave_requests";
export const LEAVE_REQUESTS_STATS = "leave_stats";
export const REPORTS_KEY = "reports";
export const EVENTS_KEY = "events";
export const PATIENTS_KEY = "patients";
export const FLEET_MANAGEMENT_KEY = "fleet_management";
export const ALLOCATIONS_KEY = "allocations key";
export const PROJECT_PROGRESS = "project_progress";
export const PROJECT_MANAGEMENT = "project_management";
export const PROJECT_LOG_FRAMES_KEY = "project_log_frames";
export const PROJECT_FILES_KEY = "project_files";
export const DISTRICTS_KEY = "districts";
export const COUNTIES_KEY = "counties";
export const VILLAGES_KEY = "villages";
export const PARISHES_KEY = "parishes";
export const SUBCOUNTIES_KEY = "subcounties";
export const SERVICES_KEY = "services";
export const PROJECT_COMMENTS_kEY = "project_comments";
export const CLV_CASES_KEY = "clv_cases";
export const PAYROLL_KEY = "payroll";
export const PAYROLL_NOTES_KEY = "payroll_notes";
export const EMPLOYEES_KEY = "employees";
export const ADVANCES_KEY = "advances";
export const ADVANCES_REQUESTS_STATS = "advances_stats";
export const IT_PRODUCTS_KEY = "it_products";
export const IT_SERVICES_KEY = "it_services";
export const REPORT_FOLDERS = "report_folders";
export const CASE_COMMENTS = "case_comments";
export const CONTRACTS = "contracts";

export const REQUISITIONS_STATS = "requisitions/stats";
export const TRAVEL_ORDER_STATS = "travel_orders/stats";
export const CLVS_STATS = "clvs/stats";
export const COMPLAINTS_STATS = "complaints/stats";
export const EVENTS_STATS = "events/stats";
export const CLIENT_STATS = "client/stats";
export const CLV_CASE_FILE_STATS = "clv/stats";

export const IS_REQUIRED = "is required";
export const REQUISITION_CREATED = "Requisition created successfully";
export const TRAVEL_ORDER_CREATED = "Travel order created successfully";

export const BADGE_COLORS = [
  "primary",
  "secondary",
  "tertiary",
  "purple",
  "red",
];

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";


