import axiosClient from "../lib/axiosClient";
import { routes } from "../lib/routes";

export const addEvent = async (event) => {
  const formData = new FormData();
  formData.append('date', event.date);
  formData.append('funder', event.funder);
  formData.append('category', event.category);
  formData.append('attendees', event.attendees);
  formData.append('age_summary', {
    "0-17": event.age0_17,
    "18-30": event.age18_30,
    "31-59": event.age31_59,
    "59": event.above59
  });
  formData.append('num_female', event.num_female);
  formData.append('num_male', event.num_male);
  formData.append('project', event.project);
  formData.append('title', event.title);
  formData.append('total_num_participants', event.total_num_participants);
  formData.append("undisclosed", event.undisclosed);
  formData.append('file', event.file);
  return axiosClient.post(routes.events.addEvent, formData);
};

export const getEvent = async (eventId) => {
  return axiosClient.get(`${routes.events.base}/${eventId}`);
};

export const getEventsStats = async () => {
  return await axiosClient.get(routes.events.getNumberOfAttendees);
};

export const deleteEvent = async (eventId) => {
  return axiosClient.delete(`${routes.events.base}/${eventId}`);
};

export const updateEvent = async (event) => {
  return axiosClient.patch(`${routes.events.editEvent}/${event.id}`, event);
};

export const getAllEvents = async () => {
  return axiosClient.get(routes.events.getAllEvents);
};
