import React, {  useEffect, useState } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import SelectField from "../../../common/SelectField";
import InputField from "../../../common/UI/InputField/InputField";
import withForm from "../../../../hoc/withForm";
import ActionButtons from "../../../Membership/Members/NewMemberForm/MultiForm/ActionButtons/ActionButtons";
import classes from "../../../Membership/Members/NewMemberForm/MultiForm/MultiForm.module.css";
import styles from "../../../Membership/Members/NewMemberForm/MultiForm/MultiForm.module.css";
import {educationLevelOptions, FIDA_clinic_locations, maritalStatusOptions, sexOptions } from "../../../../lib/options";
import {
  useCountySubcountyOptions,
  useDistrictCountyOptions,
  useDistrictOptions,
  useParishVillagesOptions,
  useSubcountyParishOptions,
} from "../../../../hooks/useUser";
import {  useProjectOptions } from "../../../../hooks/useProjects";

const ClientFormOne = ({
  page,
  limit,
  onBack,
  isSubmitting,
  setFieldValue,
  values
}) => {
  const [selectedDistrictIdForFetch, setSelectedDistrictIdForFetch] =
  useState("");
const [selectedCountyIdForFetch, setSelectedCountyIdForFetch] =
  useState("");
const [selectedSubcountyIdForFetch, setSelectedSubcountyIdForFetch] =
  useState("");
const [selectedParishIdForFetch, setSelectedParishIdForFetch] =
  useState("");
const villageOptions = useParishVillagesOptions(selectedParishIdForFetch);
const parishOptions = useSubcountyParishOptions(selectedSubcountyIdForFetch);
const countyOptions = useDistrictCountyOptions(selectedDistrictIdForFetch);
const subCountyOptions = useCountySubcountyOptions(selectedCountyIdForFetch);
  const districtOptions = useDistrictOptions();
  // const projectOptions = useOngoingProjectOptions();
  const projectOptions = useProjectOptions();
  // const getLocationNameStringById = (id, locationOptions) => {
  //   const location = locationOptions?.find(location => location.value === id)
  //   return location ? location.label : '';
  // }


useEffect(()=>{
  const selectedDistrict = districtOptions?.find(
    (d) => d.value === values.district
  );
  if (selectedDistrict) {
    setSelectedDistrictIdForFetch(selectedDistrict.id_district);
  }
  const selectedCounty = countyOptions?.find(
    (d) => d.value === values.county
  );
  if (selectedCounty) {
    setSelectedCountyIdForFetch(selectedCounty.ID_COUNTY);
  }
  const selectedSubcounty = subCountyOptions?.find(
    (d) => d.value === values.subcounty
  );
  if (selectedSubcounty) {
    setSelectedSubcountyIdForFetch(selectedSubcounty.ID_SUBCOUNTY);
  }
  const selectedParish = parishOptions?.find(
    (d) => d.value === values.parish
  );
  if (selectedParish) {
    setSelectedParishIdForFetch(selectedParish.ID_PARISH);
  }
},[values, districtOptions, countyOptions, subCountyOptions, parishOptions, ])
  

  return (
    <div className={classes.form_wrapper}>
      <div className={classes.field_wrapper}>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Name</div>
          <InputField
            placeholder="Client Name"
            name="name"
            fullWidth
            type="text"
          />
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          spacing={1}
          style={{ alignItems: "center", marginBottom: "5px" }}
        >
          <div className={styles.field_row_label}>Sex</div>
          <SelectField
            name="sex"
            placeholder="Select Gender"
            options={sexOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Email</div>
          <InputField
            placeholder="Type Here"
            name="email"
            fullWidth
            type="email"
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Age</div>
          <InputField
            placeholder="Type Here"
            name="age"
            fullWidth
            maxLength={3}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Telephone Number</div>
          <InputField
            fullWidth
            placeholder="Type Here"
            name="phoneNumber"
            maxLength={12}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Address</div>
          <InputField placeholder="Type Here" name="address" fullWidth />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Country</div>
          <InputField placeholder="Type Here" name="country" fullWidth />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>
            NATIONAL IDENTIFICATION No.
          </div>
          <InputField
            placeholder="Type Here"
            name="NIN"
            fullWidth
            maxLength={14}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>District</div>
           <SelectField
            name="district"
            placeholder={"Select District"}
            onChange={(e) => {
              const selectedDistrict = districtOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedDistrict) {
                // Set the district ID for fetching counties
                setSelectedDistrictIdForFetch(selectedDistrict.id_district);
                // Set the actual district ID in the form data
                setFieldValue(
                  "district",
                  selectedDistrict.value
                );
              }
            }}
            options={districtOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>County</div>
          <SelectField
            name="county"
            placeholder={ "Select County"}
            // disabled={!selectedDistrictIdForFetch}
            options={countyOptions}
            onChange={(e) => {
              const selectedCounty = countyOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedCounty) {
                setSelectedCountyIdForFetch(selectedCounty.ID_COUNTY);
                setFieldValue("county", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>SubCounty/Town Council</div>
          <SelectField
            name="subcounty"
            placeholder={"Select Subcounty"}
            // disabled={!selectedCountyIdForFetch}
            options={subCountyOptions}
            onChange={(e) => {
              const selectedSubcounty = subCountyOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedSubcounty) {
                setSelectedSubcountyIdForFetch(selectedSubcounty.ID_SUBCOUNTY);
                setFieldValue("subcounty", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Parish</div>
          {/* <InputField placeholder="Type Here" name="parish" fullWidth /> */}
          <SelectField
            name="parish"
            placeholder={ "Select Parish"}
            // disabled={!selectedSubcountyIdForFetch}
            options={parishOptions}
            onChange={(e) => {
              const selectedParish = parishOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedParish) {
                setSelectedParishIdForFetch(selectedParish.ID_PARISH);
                setFieldValue("parish", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Village</div>
          {/* <InputField placeholder="Type Here" name="village" fullWidth /> */}
          <SelectField
            name="village"
            placeholder={values.village || "Select Village"}
            // disabled={!selectedParishIdForFetch}
            options={villageOptions}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          spacing={1}
          style={{ alignItems: "center", marginBottom: "5px" }}
        >
          <div className={styles.field_row_label}>Marital Status</div>
          <SelectField
            name="marital_status"
            placeholder="Select Status"
            options={maritalStatusOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Accompanied By</div>
          <InputField placeholder="Type Here" name="accompaniedBy" fullWidth />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Job</div>
          <InputField placeholder="Type Here" name="occupation" fullWidth />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Place of work</div>
          <InputField placeholder="Type Here" name="place_of_work" fullWidth />
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          spacing={1}
          style={{ alignItems: "center", marginBottom: "5px" }}
        >
          <div className={styles.field_row_label}>Level of Education</div>
          <SelectField
            name="level_of_education"
            placeholder="Select Level"
            options={educationLevelOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={1} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Preferred Language</div>
          <InputField
            placeholder="Type Here"
            name="preferred_language"
            fullWidth
          />
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          spacing={1}
          style={{ alignItems: "center", marginBottom: "5px" }}
        >
          <div className={styles.field_row_label}>FIDA Clinic</div>
          <SelectField
            name="FIDA_clinic"
            placeholder="Select Location"
            options={FIDA_clinic_locations}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          spacing={1}
          style={{ alignItems: "center", marginBottom: "5px" }}
        >
          <div className={styles.field_row_label}>Project</div>
          <SelectField
            name="project"
            placeholder="Select Project"
            options={projectOptions}
          />
        </SimpleGrid>
      </div>
      <div style={{ float: "right", padding: "20px 0" }}>
        <ActionButtons
          page={page}
          onBack={onBack}
          disabled={isSubmitting}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default withForm(ClientFormOne);
