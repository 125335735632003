import { useState } from "react";
import produce from "immer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getMe,
  logIn,
  signUp,
  forgotPassword,
  setPassword,
  getAllDeactivatedUsers,
  activateUser,
  requestPasswordLink,
  updateProfile,
  getAllActivatedUsers,
  setPasswordLink,
  getAllDistricts,
  getAllSubCounties,
  getAllCounties,
  getAllVillages,
  getAllParishes,
  getDistrictCounties,
  getCountySubcounties,
  getSubcountyParishes,
  getParisheVillages,
  getLocationNames,
} from "../apis/users";
import {
  COUNTIES_KEY,
  DISTRICTS_KEY,
  PARISHES_KEY,
  SUBCOUNTIES_KEY,
  USERS_KEY,
  VILLAGES_KEY,
} from "../lib/constants";
import { loginUser } from "../store/authReducer";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useOnlineStatus from "./useOnlineStatus";

export const useUser = () => {
  return useSelector((state) => state.auth.user);
};

export const useLogin = () => {
  const dispatch = useDispatch();

  return useMutation(logIn, {
    onSuccess: (data) => {
      dispatch(loginUser(data));
    },
  });
};

export const useUpdateProfile = () => {
  const dispatch = useDispatch();

  return useMutation(updateProfile, {
    onSuccess: (data) => {
      dispatch(loginUser(data));
    },
  });
};

const networkStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  idle: "idle",
};

export const useGetMe = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(networkStatus.idle);
  const isOnline = useOnlineStatus();
  const dispatch = useDispatch();

  const isLoadingOne = status === networkStatus.loading;
  const isLoading = isLoadingOne;

  const getUserToken = async () => {
    const token = await localStorage.getItem("token");
    return token;
  };

  useEffect(() => {
    setStatus(networkStatus.loading);
    getUserToken().then((userToken) => {
      if (userToken) {
        setToken(userToken);
        getMe()
          .then((userData) => {
            setUser(userData);
            dispatch(loginUser(userData));
            setStatus(networkStatus.success);
          })
          .catch(() => {
            setUser(null);
            setStatus(networkStatus.error);
          });
      } else {
        setUser(null);
        setStatus(networkStatus.error);
      }
    });
  }, [token, dispatch]);

  useEffect(() => {
    if (!isOnline) {
      toast("You are offline. Please connect to the internet to continue.");
    }
  });

  return { user, status, isLoading, setUser };
};

export const useSignUp = () => {
  return useMutation(signUp);
};

export const useResetPassword = (type) => {
  return useMutation(type === "setPassword" ? setPasswordLink : forgotPassword);
};

export const useSetPassword = () => {
  return useMutation(setPassword);
};

export const useDeactivatedUsers = () => {
  return useQuery([USERS_KEY, "DEACTIVATED"], getAllDeactivatedUsers);
};

export const useActivatedUsers = () => {
  return useQuery([USERS_KEY, "ACTIVATED"], getAllActivatedUsers);
};

export const useUsers = () => {
  const { data } = useActivatedUsers();
  if (data?.users) {
    return data?.users.map((user) => ({
      ...user,
      id: user.id,
      name: user.full_name,
    }));
  }
  return [];
};

export const useActivateUser = () => {
  const queryClient = useQueryClient();
  const key = [USERS_KEY, "DEACTIVATED"];
  return useMutation(activateUser, {
    onMutate: async (userId) => {
      await queryClient.cancelMutations(key);
      const prevUsers = queryClient.getQueryData(key);
      if (prevUsers) {
        queryClient.setQueryData(key, (prevUsers) => {
          return produce(prevUsers, (draft) => {
            draft.users.filter((u) => u.id !== userId);
          });
        });
      } else {
        queryClient.setQueryData(key, () => {
          return { users: [] };
        });
      }
    },
    onError: (_error, _userId, context) => {
      queryClient.setQueryData(key, context.prevUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useRequestPasswordLink = () => {
  const queryClient = useQueryClient();
  const key = [USERS_KEY, "DEACTIVATED"];
  return useMutation(requestPasswordLink, {
    onMutate: async (userId) => {
      await queryClient.cancelMutations(key);
      const prevUsers = queryClient.getQueryData(key);
      if (prevUsers) {
        queryClient.setQueryData(key, (prevUsers) => {
          return produce(prevUsers, (draft) => {
            draft.users.filter((u) => u.id !== userId);
          });
        });
      } else {
        queryClient.setQueryData(key, () => {
          return { users: [] };
        });
      }
    },
    onError: (_error, _userId, context) => {
      queryClient.setQueryData(key, context.prevUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries(key);
    },
  });
};

export const useDistricts = () => {
  return useQuery(DISTRICTS_KEY, getAllDistricts);
};

export const useCounties = () => {
  return useQuery(COUNTIES_KEY, getAllCounties);
};

export const useVillages = () => {
  return useQuery(VILLAGES_KEY, getAllVillages);
};

export const useParishes = () => {
  return useQuery(PARISHES_KEY, getAllParishes);
};

export const useVillagesOptions = () => {
  const { data } = useVillages();
  if (data?.villages) {
    return data?.villages.map((county) => ({
      value: county.id,
      label: county.NAME,
    }));
  }
};
export const useParishesOptions = () => {
  const { data } = useParishes();
  if (data?.parishes) {
    return data?.parishes.map((county) => ({
      value: county.id,
      label: county.NAME,
    }));
  }
};
export const useCountyOptions = () => {
  const { data } = useCounties();
  if (data?.counties) {
    return data?.counties.map((county) => ({
      value: county.id,
      label: county.NAME,
    }));
  }
};

export const useSubcounties = () => {
  return useQuery(SUBCOUNTIES_KEY, getAllSubCounties);
};

export const useDistrictOptions = () => {
  const { data } = useDistricts();
  if (data?.districts) {
    return data?.districts.map((district) => ({
      value: district.id,
      label: district.NAME,
      id_district: district.ID_DISTRICT,
    }));
  }
  return [];
};

export const useSubcountyOptions = () => {
  const { data } = useSubcounties();
  if (data?.subcounties) {
    return data?.subcounties.map((subcounty) => ({
      value: subcounty.id,
      label: subcounty.NAME,
      id_county: subcounty.ID_COUNTY,
    }));
  }
  return [];
};

export const useDistrictCounties = (districtId) => {
  return useQuery([DISTRICTS_KEY, districtId, COUNTIES_KEY], () =>
    getDistrictCounties(districtId)
  );
};

export const useCountySubcounties = (countyId) => {
  return useQuery([COUNTIES_KEY, countyId, SUBCOUNTIES_KEY], () =>
    getCountySubcounties(countyId)
  );
};

export const useSubcountyParishes = (parishId) => {
  return useQuery([PARISHES_KEY, parishId, PARISHES_KEY], () =>
    getSubcountyParishes(parishId)
  );
};

export const useParishVillages = (parishId) => {
  return useQuery([VILLAGES_KEY, parishId, VILLAGES_KEY], () =>
    getParisheVillages(parishId)
  );
};

export const useDistrictCountyOptions = (districtId) => {
  const { data } = useDistrictCounties(districtId);
  if (data?.counties) {
    return data?.counties.map((county) => ({
      ...county,
      value: county.id,
      label: county.NAME,
      id_district: county.ID_DISTRICT,
    }));
  }
  return [];
};

export const useCountySubcountyOptions = (countyId) => {
  const { data } = useCountySubcounties(countyId);
  if (data?.subcounties) {
    return data?.subcounties.map((subcounty) => ({
      ...subcounty,
      value: subcounty.id,
      label: subcounty.NAME,
      id_county: subcounty.ID_COUNTY,
    }));
  }
  return [];
};

export const useSubcountyParishOptions = (subcountyId) => {
  const { data } = useSubcountyParishes(subcountyId);
  if (data?.parishes) {
    return data?.parishes.map((parish) => ({
      ...parish,
      value: parish.id,
      label: parish.NAME,
    }));
  }
  return [];
};

export const useParishVillagesOptions = (parishId) => {
  const { data } = useParishVillages(parishId);
  if (data?.villages) {
    return data?.villages.map((village) => ({
      ...village,
      value: village.id,
      label: village.NAME,
      id_village: village.ID_PARISH,
    }));
  }
  return [];
};

export const useLocationNames = (locationIds) => {
  return useQuery(["locationNames", locationIds], () =>
    getLocationNames(locationIds)
  );
};

export const getLocationNameStringById = (locationData, locationOptions) => {
  if (!locationData) return "Not found";

  const { district, county, subcounty, parish, village } = locationData;
// console.log('locationData', locationData)
  return {
    district:  district?.NAME,
    county: county?.NAME,
    subcounty:  subcounty?.NAME,
    parish: parish?.NAME,
    village:  village?.NAME,
  };
};
