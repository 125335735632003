import { SimpleGrid, Button, FormLabel } from "@chakra-ui/react";
import TextField from "../../common/TextField";
import TextAreaField from "../../common/TextAreaField";
import withForm from "../../../hoc/withForm";
import { useProjectOptions } from "../../../hooks/useProjects";
import SelectInputField from "../../common/UI/SelectInputField/SelectInputField";

const TravelOrderForm = ({ onSubmit, isSubmitting, setFieldValue }) => {
  const projectOptions = useProjectOptions();
  return (
    <SimpleGrid p={5} gap={5}>
      <SimpleGrid columns={2} gap={5}>
        <TextField
          name="date_requested"
          placeholder="Date Requested"
          label="Date Requested"
          type="date"
        />
        <div></div>
      </SimpleGrid>
      <SimpleGrid columns={2} gap={5}>
        <div>
          <FormLabel fontSize="lg" fontWeight="bold" htmlFor="project">
            Project
          </FormLabel>
          <SelectInputField
            data={projectOptions}
            name="project"
            placeholder="Choose a project"
            setFieldValue={setFieldValue}
          />
        </div>
        <TextField
          name="journey_start_time"
          placeholder="Journey start time"
          label="Journey start Date & time"
          type="dateTime-local"
        />
        <TextField
          name="journey_end_time"
          placeholder="Journey end date & time"
          label="Journey end date & time"
          type="dateTime-local"
        />
      </SimpleGrid>
      <SimpleGrid columns={2} gap={5}>
        <TextField
          name="pickup_location"
          placeholder="Pick up location"
          label="Pick up location"
        />
        <TextField
          name="destination"
          placeholder="Destination"
          label="Destination"
        />
      </SimpleGrid>
      <TextAreaField
        name="project_activity"
        placeholder="Project Activity"
        label="Project Activity"
      />
      <TextAreaField name="purpose" placeholder="Purpose" label="Purpose" />
      <Button
        mt={5}
        type="submit"
        borderRadius="full"
        bgGradient="linear(to-r, purple.400, purple.700)"
        _hover={{ bgGradient: "linear(to-r, purple.600, purple.900)" }}
        size="lg"
        w="100%"
        color="white"
        isLoading={isSubmitting}
      >
        Add Travel Order
      </Button>
    </SimpleGrid>
  );
};

export default withForm(TravelOrderForm);
