import {
  MdDashboard,
  MdTaskAlt,
  MdStorage,
  MdSummarize,
  MdFeedback,
} from "react-icons/md";

let id = 0;

const menuOptions = {
  dashboard: {
    id: ++id,
    name: "Dashboard",
    icon: MdDashboard,
    path: "/",
  },
  tasks: {
    id: ++id,
    name: "Tasks",
    icon: MdTaskAlt,
    path: "tasks",
  },
  clvDatabase: {
    id: ++id,
    name: "CLV Database",
    icon: MdSummarize,
    path: "clv-database",
  },
  clvCaseFiles: {
    id: ++id,
    name: "CLV Case Files",
    icon: MdSummarize,
    path: "clv-case-files",
  },
  clvs: {
    id: ++id,
    name: "CLVs",
    icon: MdSummarize,
    path: "clvs",
  },
  clvComplaints: {
    id: ++id,
    name: "CLV Complaints",
    icon: MdFeedback,
    path: "clv-complaints",
  },
  itProducts: {
    id: ++id,
    name: "IT Products",
    icon: MdSummarize,
    path: "it-products",
  },
  itServices: {
    id: ++id,
    name: "IT Services",
    icon: MdSummarize,
    path: "it-services",
  },
  itComplaint: {
    id: ++id,
    name: "IT Complaints",
    icon: MdSummarize,
    path: "it-complaints",
  },
  systemAdmin: {
    id: ++id,
    name: "System Admin",
    icon: MdSummarize,
    path: "system-admin",
  },
  caseFiles: {
    id: ++id,
    name: "Case Files",
    icon: MdStorage,
    path: "case-files",
  },
  legalOfficer: {
    id: ++id,
    name: "Legal Officer",
    icon: MdStorage,
    path: "legal-officer",
  },
  clientsFiles: {
    id: ++id,
    name: "Clients Files",
    icon: MdStorage,
    path: "clients-files",
  },
  fidaProjects: {
    id: ++id,
    name: "FIDA Projects",
    icon: MdStorage,
    path: "fida-projects",
  },
  fidaDatabases: {
    id: ++id,
    name: "FIDA Databases",
    icon: MdStorage,
    path: "fida-databases",
  },
  fidaAssets: {
    id: ++id,
    name: "FIDA Assets",
    icon: MdStorage,
    path: "fida-assets",
  },
  clients: {
    id: ++id,
    name: "Clients",
    icon: MdStorage,
    path: "clients",
  },
  approvals: {
    id: ++id,
    name: "Approvals",
    icon: MdStorage,
    path: "approvals",
  },
  members: {
    id: ++id,
    name: "Members",
    icon: MdStorage,
    path: "members",
  },
  membersActivities: {
    id: ++id,
    name: "Members Activities",
    icon: MdStorage,
    path: "members-activities",
  },
  allocations: {
    id: ++id,
    name: "Allocations",
    icon: MdStorage,
    path: "allocations",
  },
  notifications: {
    id: ++id,
    name: "Notifications",
    icon: MdStorage,
    path: "notifications",
  },
  humanResource_Approvals: {
    id: ++id,
    name: "Approvals",
    icon: MdStorage,
    path: "hr-approvals",
  },
  payRoll: {
    id: ++id,
    name: "PayRoll",
    icon: MdStorage,
    path: "payroll",
  },
  leaveMgt: {
    id: ++id,
    name: "Leave Management",
    icon: MdStorage,
    path: "leave-management",
  },
  Contracts: {
    id: ++id,
    name: "Contracts",
    icon: MdStorage,
    path: "contracts",
  },
  Employees: {
    id: ++id,
    name: "Employees",
    icon: MdStorage,
    path: "employees",
  },
  fidaAssets_hr: {
    id: ++id,
    name: "Fida Assests",
    icon: MdStorage,
    path: "fida-assets",
  },
  reports: {
    id: ++id,
    name: "Reports",
    icon: MdStorage,
    path: "reports",
  },
  fidaApprovals: {
    id: ++id,
    name: "FIDA IIMS Approvals",
    icon: MdStorage,
    path: "fida-iims-approvals",
  },
  project: {
    id: ++id,
    name: "Project Files",
    icon: MdStorage,
    path: "project-files",
  },
  fleetDatabase: {
    id: ++id,
    name: "Fleet Database",
    icon: MdStorage,
    path: "fleet-database",
  },
  procurements: {
    id: ++id,
    name: "Procurements",
    icon: MdStorage,
    path: "procurements",
  },
};

const commonOptions = [menuOptions.dashboard, menuOptions.tasks];

const legalAidOptions = [
  ...commonOptions,
  menuOptions.clients,
  menuOptions.clvs,
  menuOptions.caseFiles,
  menuOptions.clvCaseFiles,
  menuOptions.project,
  menuOptions.reports,
];

const procurementOptions = [
  ...commonOptions,
  menuOptions.approvals,
  menuOptions.procurements,
  menuOptions.fidaAssets,
  menuOptions.reports,
];

const accountsOptions = [
  ...commonOptions,
  menuOptions.approvals,
  menuOptions.payRoll,
  menuOptions.fidaProjects,
  menuOptions.fidaDatabases,
  menuOptions.reports,
];

const programsOptions = [
  ...commonOptions,
  menuOptions.approvals,
  menuOptions.fidaProjects,
  menuOptions.fidaDatabases,
  menuOptions.fidaAssets,
  menuOptions.reports,
];

const financeOptions = [
  ...commonOptions,
  menuOptions.approvals,
  menuOptions.payRoll,
  menuOptions.fidaProjects,
  menuOptions.fidaDatabases,
  menuOptions.reports,
];

const communicationOptions = [...commonOptions];

const m$eOptions = [
  ...commonOptions,
  menuOptions.approvals,
  menuOptions.fidaProjects,
  menuOptions.fidaDatabases,
  menuOptions.fidaAssets,
  menuOptions.reports,
]

const hrOptions = [
  ...commonOptions,
  menuOptions.humanResource_Approvals,
  menuOptions.payRoll,
  menuOptions.leaveMgt,
  menuOptions.Employees,
  menuOptions.Contracts,
  menuOptions.fidaAssets_hr,
  menuOptions.reports,
  menuOptions.fidaApprovals,
]

const projectOptions = [
  ...commonOptions,
  menuOptions.fidaProjects,
  menuOptions.reports,
]

const transportOptions = [
  ...commonOptions,
  menuOptions.fleetDatabase,
  menuOptions.reports,
]

const menu = {
  clv: [
    ...commonOptions,
    menuOptions.clvs,
    menuOptions.caseFiles,
    menuOptions.clvDatabase,
    // menuOptions.clvComplaints,
    menuOptions.reports,
  ],
  courtProcessServer: [
    ...commonOptions,
    menuOptions.caseFiles,
    menuOptions.legalOfficer,
    menuOptions.reports,
  ],
  communicationsOfficer: communicationOptions,
  communicationsAssistant: communicationOptions,
  legalAid: legalAidOptions,
  legalOfficer: legalAidOptions,
  legalAssistant: legalAidOptions,
  legalAssociate: legalAidOptions,
  ceo: [
    ...hrOptions,
    ...m$eOptions,
    menuOptions.clients,
    menuOptions.clvs,
    menuOptions.caseFiles,
    menuOptions.clvCaseFiles,
    menuOptions.project,
    menuOptions.reports,
  ],
  membership: [
    ...commonOptions,
    menuOptions.approvals,
    menuOptions.members,
    menuOptions.membersActivities,
    menuOptions.allocations,
    menuOptions.notifications,
  ],
  humanResources: hrOptions,
  hrConsultant: hrOptions,
  it: [
    ...commonOptions,
    menuOptions.itProducts,
    menuOptions.itServices,
    menuOptions.itComplaint,
    // menuOptions.systemAdmin,
    menuOptions.reports,
  ],
  finance: financeOptions,
  managerOfFinance: financeOptions,
  directorOfFinanceAndAdmin: financeOptions,
  seniorAccountant: accountsOptions,
  accountant: accountsOptions,
  projectAccountant: accountsOptions,
  me: m$eOptions,
  seniorMeOfficer: m$eOptions,
  meOfficer: m$eOptions,
  programs: programsOptions,
  dop: programsOptions,
  programsManager: programsOptions,
  programsAdvisor: programsOptions,
  programsConsultant: programsOptions,
  programAssistant: programsOptions,
  projectsCoordinator: projectOptions,
  projectOfficer: projectOptions,
  procurement: procurementOptions,
  procurementOfficer: procurementOptions,
  counsellor: [...commonOptions, menuOptions.clientsFiles, menuOptions.reports],
  fleetManager: transportOptions,
  transportAssistant: transportOptions,
  other: [...commonOptions],
};

export default menu;
