import {
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "../common/Modal";
import SectionHeader from "../common/SectionHeader";
import IncomingTask from "./IncomingTasks/IncomingTask";
import InProgressTask from "./InProgressTasks/InProgressTask";
import NewTaskForm from "./NewTask/NewTaskForm";
import SubHeading from "./SubHeading/SubHeading";
import { useAddTask, useTasks } from "../../hooks/useTasks";
import { toastSuccess } from "../../lib/toastDetails";
import CompletedTask from "./InProgressTasks/CompletedTask";
import { AiOutlineSearch } from "react-icons/ai";
import classes from "./Tasks.module.css";
import Loader from "./../common/UI/Loader/Loader";
import Pagination from "../common/Pagination/Pagination";
import NewTaskCard from "./NewTask/NewTaskCard";

const PAGE_SIZE = 6;

const Tasks = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isError, error, isSuccess, isLoading } = useAddTask();
  const toast = useToast();
  const tasksData = useTasks();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);

  const dataSets = useMemo(() => {
    if (!tasksData.data) return [];
    return tasksData.data.tasks;
  }, [tasksData]);

  useEffect(() => {
    setData(dataSets);
  }, [dataSets]);

  // Setting data to initial state when search term is empty
  useEffect(() => {
    if (searchTerm.length < 1) {
      setData(dataSets);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (isSuccess) {
      toast(toastSuccess("Task Added Successfully"));
      onClose();
    }
  }, [isSuccess, toast, onClose]);

  const keyWordSearchHandler = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (searchTerm !== "") {
      const Results = tasksData.data.tasks.filter((Result) => {
        return Object.values(Result)
          .join(" ")
          .replace(/-/g, " ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setData(Results);
    }
  };

  const [currentPage, setCurrentPage] = useState({
    inComingTasks: 1,
    inProgressTasks: 1,
    completedTasks: 1,
  });

  const toDoTasks = data.filter((task) => task.status === "todo");
  const progressTasks = data.filter((task) => task.status === "pending");
  const completedTasks = data.filter((task) => task.status === "completed");

  const currentIncomingTasks = useMemo(() => {
    const firstPageIndex = (currentPage.inComingTasks - 1) * 5;
    const lastPageIndex = firstPageIndex + 5;
    return toDoTasks.slice(firstPageIndex, lastPageIndex);
  }, [currentPage.inComingTasks, toDoTasks]);

  const currentInProgressTasks = useMemo(() => {
    const firstPageIndex = (currentPage.inProgressTasks - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return progressTasks.slice(firstPageIndex, lastPageIndex);
  }, [currentPage.inProgressTasks, progressTasks]);

  const currentCompletedTasks = useMemo(() => {
    const firstPageIndex = (currentPage.completedTasks - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return completedTasks.slice(firstPageIndex, lastPageIndex);
  }, [currentPage.completedTasks, completedTasks]);

  return (
    <>
      <SectionHeader title="Tasks" />
      {tasksData.isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.tasks_search_wrapper}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<AiOutlineSearch color="gray.300" />}
              />
              <Input
                className={classes.search_input_field}
                type="search"
                value={searchTerm}
                disabled={isLoading || tasksData?.isLoading}
                onChange={keyWordSearchHandler}
                placeholder="Type to Search"
              />
            </InputGroup>
          </div>

          <SubHeading title="incoming Tasks" />
          <SimpleGrid
      columns={3}
      spacing={4}
    >
      <NewTaskCard onOpen={onOpen} />
    </SimpleGrid>

          {currentIncomingTasks.length > 0 && (
            <>
              <IncomingTask onOpen={onOpen} tasks={currentIncomingTasks} />
              <div className="d-flex justify-content-center my-2">
                <Pagination
                  className="justify-content-center"
                  currentPage={currentPage.inComingTasks}
                  totalCount={toDoTasks.length}
                  pageSize={5}
                  onPageChange={(page) =>
                    setCurrentPage({ ...currentPage, inComingTasks: page })
                  }
                />
              </div>
            </>
          )}

<SubHeading title="In Progress" />
          {currentInProgressTasks.length > 0 && (
            <>
              <InProgressTask tasks={currentInProgressTasks} />
              <div className="d-flex justify-content-center my-2">
                <Pagination
                  className="justify-content-center"
                  currentPage={currentPage.inProgressTasks}
                  totalCount={progressTasks.length}
                  pageSize={PAGE_SIZE}
                  onPageChange={(page) =>
                    setCurrentPage({ ...currentPage, inProgressTasks: page })
                  }
                />
              </div>
            </>
          )}

<SubHeading title="Completed" />
          {currentCompletedTasks.length > 0 && (
            <>
              <CompletedTask tasks={currentCompletedTasks} />
              <div className="d-flex justify-content-center my-2">
                <Pagination
                  className="justify-content-center"
                  currentPage={currentPage.completedTasks}
                  totalCount={completedTasks.length}
                  pageSize={PAGE_SIZE}
                  onPageChange={(page) =>
                    setCurrentPage({ ...currentPage, completedTasks: page })
                  }
                />
              </div>
            </>
          )}
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose} title="Create a Task" size="2xl">
        <NewTaskForm
          onSubmit={mutate}
          error={error}
          isError={isError}
          isSubmitting={isLoading}
        />
      </Modal>
    </>
  );
};

export default Tasks;
