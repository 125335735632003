import moment from "moment";
import * as yup from "yup";

export const taskInitialValues = {
  title: "",
  description: "",
  startDate: "",
  endDate: "",
  tags: [],
  attachments: [],
  status: "todo",
  team: [],
  outline: [],
};
export const taskSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  tags: yup
    .array()
    .of(yup.string())
    .required("A task should atleast have one tag"),
  team: yup
    .array()
    .of(yup.string())
    .required("A task should atleast be attached to one member"),
  startDate: yup.string().required("Start date is required"),
  endDate: yup
    .string()
    .required("End Date is required")
    .test(
      "isGreaterThanStart",
      "End date must be greater than start date",
      (value, context) => {
        const { parent } = context;
        return moment(value).isSameOrAfter(parent.startDate);
      }
    ),
});
