import React, { useEffect } from "react";
import {
  Avatar,
  Flex,
  Center,
  Spinner,
  useToast,
  Button,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { RiAttachment2, RiMessage2Line, RiFile2Fill  } from "react-icons/ri";
import { AttachmentIcon } from "../../../assets/Icons/Icons";
import { DepartmentButton } from "../TaskCard/TaskCard";
import classes from "./TaskSummary.module.css";
import styles from "../NewTask/NewTaskForm.module.css";
import FormButton from "../../common/UI/FormButton/FormButton";
import { useTaskComments } from "../../../hooks/useTasks";
import { Form, Formik } from "formik";
import TextField from "../../common/TextField";
import { toastError } from "../../../lib/toastDetails";
import { BADGE_COLORS } from "../../../lib/constants";

const CommentCard = (props) => {
  const { createdBy, body } = props;
  return (
    <div className={classes.comment_card_wrapper}>
      <div className={classes.card_header}>
        <Avatar size="sm" name={createdBy?.full_name} src={createdBy?.image} />
        <div className={classes.comment_title}>
          <h3>{createdBy?.full_name ? createdBy?.full_name : "Fida Member"}</h3>
        </div>
      </div>
      <p>{body}</p>
    </div>
  );
};

const TaskSummary = ({
  task,
  onSubmit,
  isSubmitting,
  isError,
  error,
  btnLabel,
  onChangeStatus,
  isChangingStatus,
}) => {
  const { data, isLoading } = useTaskComments(task.id);
  const { title, description, outline, tags, attachmentKeys, attachmentDownloadLinks} = task;
  const toast = useToast();

  useEffect(() => {
    if (isError) {
      toast(toastError(error));
    }
  }, [isError, error, toast]);

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        onSubmit(task.id);
      }}
    >
      <div className={classes.task__summary_wrapper}>
        <div className={classes.task_tags_wrapper}>
          <Flex flexWrap="wrap">
            {tags
              .filter((tag) => tag.length > 0)
              .map((tag) => (
                <DepartmentButton
                  title={tag}
                  btnColor={
                    BADGE_COLORS[
                      Math.floor(Math.random() * BADGE_COLORS.length)
                    ]
                  }
                />
              ))}
          </Flex>
        </div>
        <div className={classes.task_content_wrapper}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className={styles.asset_tags_team_wrapper}>
          <h6>
            <AttachmentIcon />
            Team
          </h6>
          <div className={styles.team}>
            {task.team.length > 0 ? (
              task.team.map((team) => (
                <Tag size="lg" colorScheme="purple" borderRadius="full" m={1}>
                  <Avatar
                    src={team?.image}
                    size="xs"
                    name={team?.full_name ? team?.full_name : "Fida Member"}
                    ml={-1}
                    mr={2}
                    title={team?.full_name ? team?.full_name : "Fida Member"}
                  />
                  <TagLabel>
                    {team?.full_name ? team?.full_name : "Fida Member"}
                  </TagLabel>
                </Tag>
              ))
            ) : (
              <p>No member assigned to this task</p>
            )}
          </div>
        </div>

        <div className={styles.asset_outline_wrapper}>
          <h6>
            <AttachmentIcon /> Outlines({outline.length})
          </h6>
          <div
            className={`${styles.outline_list} ${classes.outline_list_preview}`}
          >
            <ul>
              {outline.map((outline) => {
                return <li key={outline}>{outline}</li>;
              })}
            </ul>
          </div>
          <div className={styles.asset_outline_wrapper}>
            <h6>
              <RiAttachment2 /> Attachment
            </h6>
            <div className={classes.attachement_preview_wrapper}>
           { attachmentKeys.length >0?
              <>{attachmentKeys?.map((fileName, index) => (
                <div key={fileName} className={classes.attachment_item}>
                  <RiFile2Fill />
                  <h6 className={classes.file_name}>{fileName.length > 15 ? fileName.substring(0, 40) + '...' : fileName}</h6>
                  <a
                    href={attachmentDownloadLinks[index]}
                    download={fileName}
                    className={classes.download_link}
                  >
                    Save
                  </a>
                </div>
              ))}</>:
              <h6>No files attached</h6>
            }
            </div>
          </div>

          <div className={styles.asset_outline_wrapper}>
            <h6>
              <RiMessage2Line /> Comments (
              {data?.comments && data?.comments.length})
            </h6>
            <div className={classes.comments_wrapper}>
              {isLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {data?.comments &&
                data?.comments.map((comment) => (
                  <CommentCard key={comment.id} {...comment} />
                ))}
            </div>
          </div>
          <Formik
            initialValues={{ body: "", task: task.id }}
            onSubmit={(values, { resetForm }) => {
              // alert(JSON.stringify(values, null, 2));
              onSubmit(values);
              resetForm();
            }}
          >
            <Form>
              <div className={styles.asset_outline_wrapper}>
                <h6>
                  <RiMessage2Line /> Add Comment
                </h6>
                <div className={classes.new_comments_wrapper}>
                  <TextField
                    variant="outline"
                    placeholder="Type your comment here"
                    name="body"
                    type="text"
                  />
                </div>
              </div>
              <FormButton
                variant="colored"
                rounded={true}
                type="submit"
                isSubmitting={isSubmitting}
              >
                Add Comment
              </FormButton>
            </Form>
          </Formik>
        </div>
        {btnLabel && (
          <Button
            isFullWidth
            my={5}
            colorScheme="purple"
            onClick={() => onChangeStatus(task.id)}
            isLoading={isChangingStatus}
          >
            {btnLabel}
          </Button>
        )}
      </div>
    </Formik>
  );
};

export default TaskSummary;
