import classes from "../../../../Membership/Members/NewMemberForm/MultiForm/MultiForm.module.css";
import styles from "./MultiForm.module.css";
import { SimpleGrid } from "@chakra-ui/react";
import InputField from "../../../../common/UI/InputField/InputField";
import ActionButtons from "../../../../Membership/Members/NewMemberForm/MultiForm/ActionButtons/ActionButtons";
import SelectField from "./../../../../common/SelectField";
import SearchableField from "./../../../../common/UI/SearchableField/SearchableField";
import { useClientOptions } from "../../../../../hooks/useClients";
import { useClvsDetails } from "../../../../../hooks/useClv";
import withForm from "../../../../../hoc/withForm";
import { educationLevelOptions, maritalStatusOptions, sexOptions } from "../../../../../lib/options";
import {
  useDistrictCountyOptions,
  useDistrictOptions,
  useCountySubcountyOptions,
  useSubcountyParishOptions,
  useParishVillagesOptions,
  useLocationNames,
  getLocationNameStringById
} from "../../../../../hooks/useUser";
import { useEffect, useState } from "react";

const MultForm1 = ({
  page,
  isClvCaseFile,
  isNew,
  limit,
  onBack,
  isSubmitting,
  selectedClient,
  setSelectedClient,
  setSelectedClvName,
  selectedClvName,
  setFieldValue,
  values
}) => {
  const [selectedDistrictIdForFetch, setSelectedDistrictIdForFetch] =
    useState("");
  const [selectedCountyIdForFetch, setSelectedCountyIdForFetch] =
    useState("");
  const [selectedSubcountyIdForFetch, setSelectedSubcountyIdForFetch] =
    useState("");
  const [selectedParishIdForFetch, setSelectedParishIdForFetch] =
    useState("");
  const clvs = useClvsDetails();
  const clients = useClientOptions();
  const villageOptions = useParishVillagesOptions(selectedParishIdForFetch);
  const parishOptions = useSubcountyParishOptions(selectedSubcountyIdForFetch);
  const countyOptions = useDistrictCountyOptions(selectedDistrictIdForFetch);
  const subcountyOptions = useCountySubcountyOptions(selectedCountyIdForFetch);
  const districtOptions = useDistrictOptions();

  const locationIds = {
    districtId: selectedClient?.district,
    countyId: selectedClient?.county,
    subcountyId: selectedClient?.subcounty,
    parishId: selectedClient?.parish,
    villageId: selectedClient?.village,
  };

  const { data: locationData} = useLocationNames(locationIds);
  const locationNames = getLocationNameStringById(locationData?.data);

  //   const getLocationNameStringById = (id, locationOptions) => {
  //   const location = locationOptions?.find(location => location.value === id)
  //   return location ? location.label : '';
  // }

  useEffect(()=>{
    const selectedDistrict = districtOptions?.find(
      (d) => d.value === values.respondentDistrict
    );
    if (selectedDistrict) {
      setSelectedDistrictIdForFetch(selectedDistrict.id_district);
    }
    const selectedCounty = countyOptions?.find(
      (d) => d.value === values.respondentCounty
    );
    if (selectedCounty) {
      setSelectedCountyIdForFetch(selectedCounty.ID_COUNTY);
    }
    const selectedSubcounty = subcountyOptions?.find(
      (d) => d.value === values.respondentSubcounty
    );
    if (selectedSubcounty) {
      setSelectedSubcountyIdForFetch(selectedSubcounty.ID_SUBCOUNTY);
    }
    const selectedParish = parishOptions?.find(
      (d) => d.value === values.respondentParish
    );
    if (selectedParish) {
      setSelectedParishIdForFetch(selectedParish.ID_PARISH);
    }
  },[values, districtOptions, countyOptions, subcountyOptions, parishOptions, ])

  const selectedClv =
    selectedClvName?.first_name + " " + selectedClvName?.last_name;

  return (
    <div className={classes.form_wrapper}>
      {isClvCaseFile && (
        <div className={classes.field_wrapper}>
          <div className={classes.field_label}>CLV Details</div>
          <SimpleGrid
            columns={3}
            spacing={5}
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <SearchableField
              placeholder="CLV Name"
              data={clvs}
              setSelectedItem={setSelectedClvName}
              selectedItem={selectedClvName.name}
              name="clvName"
              defaultValue={selectedClvName?.first_name ? selectedClv : null}
            />
            <div className={styles.selected_project_name}>
              {selectedClvName.project?.name}
            </div>
            <div style={{ textAlign: "center" }}>
              {selectedClvName.district}
            </div>
          </SimpleGrid>
        </div>
      )}
      <div className={classes.field_wrapper}>
        <div className={classes.field_label}>1. Personal Address</div>
        <div className={classes.field_wrapper}>
          <SimpleGrid columns={3} spacing={2}>
            <div>BIO DATA</div>
            <div>COMPLAINANT</div>
            <div>RESPONDENT</div>
          </SimpleGrid>
        </div>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Name</div>
          <SearchableField
            placeholder="Type client Name"
            data={clients}
            setSelectedItem={setSelectedClient}
            selectedItem={selectedClient?.name}
            name="searchClient"
            defaultValue={selectedClient?.name}
          />

          <InputField placeholder="Type Here" name="respondentName" fullWidth />
        </SimpleGrid>
        <SimpleGrid
          columns={3}
          spacing={2}
          style={{ alignItems: "center", marginBottom: 10 }}
        >
          <div className={styles.field_row_label}>Sex</div>
          <div>{selectedClient?.sex}</div>
          <SelectField
            name="respondentSex"
            placeholder="Select Sex"
            options={sexOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Age</div>
          <div>{selectedClient?.age}</div>
          <InputField
            placeholder="Type Here"
            maxLength="3"
            name="respondentAge"
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Telephone Number</div>
          <div>{selectedClient?.phoneNumber}</div>
          <InputField
            placeholder="Type Here"
            name="respondentPhone"
            maxLength="12"
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Country</div>
          <div>{selectedClient?.country}</div>
          <InputField placeholder="Type Here" name="respondentCountry" />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>
            NATIONAL IDENTIFICATION No.
          </div>
          <div>{selectedClient?.NIN}</div>
          <InputField
            placeholder="Type Here"
            name="respondentNIN"
            maxLength="14"
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>District</div>
          <div>{locationNames?.district || selectedClient?.district}</div>
          {/* <InputField placeholder="Type Here" name="respondentDistrict" /> */}
          <SelectField
            name="respondentDistrict"
            placeholder="Select District"
            onChange={(e) => {
              const selectedDistrict = districtOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedDistrict) {
                // Set the district ID for fetching counties
                setSelectedDistrictIdForFetch(selectedDistrict.id_district);
                // Set the actual district ID in the form data
                setFieldValue(
                  "respondentDistrict",
                  selectedDistrict.value
                );
              }
            }}
            options={districtOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>County</div>
          <div>{locationNames?.county || selectedClient?.county}</div>
          <SelectField
            name="respondentCounty"
            placeholder="Select County"
            // disabled={!selectedDistrictIdForFetch}
            options={countyOptions}
            onChange={(e) => {
              const selectedCounty = countyOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedCounty) {
                setSelectedCountyIdForFetch(selectedCounty.ID_COUNTY);
                setFieldValue("respondentCounty", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Subcounty</div>
          <div>{locationNames?.subcounty || selectedClient?.subcounty}</div>
          <SelectField
            name="respondentSubcounty"
            placeholder="Select Subcounty"
            // disabled={!selectedCountyIdForFetch}
            options={subcountyOptions}
            onChange={(e) => {
              const selectedSubcounty = subcountyOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedSubcounty) {
                setSelectedSubcountyIdForFetch(selectedSubcounty.ID_SUBCOUNTY);
                setFieldValue("respondentSubcounty", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Parish</div>
          <div>{locationNames?.parish || selectedClient?.parish}</div>
          {/* <InputField placeholder="Type Here" name="respondentParish" /> */}
          <SelectField
            name="respondentParish"
            placeholder="Select Parish"
            // disabled={!selectedSubcountyIdForFetch}
            options={parishOptions}
            onChange={(e) => {
              const selectedParish = parishOptions?.find(
                (d) => d.value === e.target.value
              );
              if (selectedParish) {
                setSelectedParishIdForFetch(selectedParish.ID_PARISH);
                setFieldValue("respondentParish", e.target.value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Village</div>
          <div>{locationNames?.village || selectedClient?.village}</div>
          {/* <InputField placeholder="Type Here" name="respondentVillage" /> */}
          <SelectField
            name="respondentVillage"
            placeholder="Select Village"
            // disabled={!selectedParishIdForFetch}
            options={villageOptions}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={3}
          spacing={2}
          style={{ alignItems: "center", marginBottom: 8 }}
        >
          <div className={styles.field_row_label}>Marital Status</div>
          <div>{selectedClient?.marital_status}</div>
          <SelectField
            name="respondentMaritalStatus"
            placeholder="Select Status"
            options={maritalStatusOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Accompanied By</div>
          <div>{selectedClient?.accompaniedBy}</div>
          <InputField placeholder="Type Here" name="respondentAccompaniedBy" />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Job</div>
          <div>{selectedClient?.occupation}</div>
          <InputField placeholder="Type Here" name="respondentJob" />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Place of work</div>
          <div>{selectedClient?.place_of_work}</div>
          <InputField placeholder="Type Here" name="respondentPlaceOfWork" />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Level of Education</div>
          <div>{selectedClient?.level_of_education}</div>
          <SelectField
            name="respondentLevelOfEducation"
            placeholder="Select Level"
            options={educationLevelOptions}
          />
          {/* <InputField
            placeholder="Type Here"
            name="respondentLevelOfEducation"
          /> */}
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>Preferred Language</div>
          <div>{selectedClient?.preferred_language}</div>
          <InputField placeholder="Type Here" name="respondentLanguage" />
        </SimpleGrid>
        <SimpleGrid columns={3} spacing={2} style={{ alignItems: "center" }}>
          <div className={styles.field_row_label}>
            Relationship with Respondent
          </div>
          <div></div>
          <InputField placeholder="Type Here" name="respondentRelation" />
        </SimpleGrid>
      </div>
      <ActionButtons
        page={page}
        onBack={onBack}
        disabled={isSubmitting}
        loading={isSubmitting}
        limit={limit}
      />
    </div>
  );
};

export default withForm(MultForm1);
