import React, { useEffect, useState } from "react";
import classes from "../../HumanResource/FidaAssets/NewAsset/NewAsset.module.css";
import styles from "./NewTaskForm.module.css";
import InputField from "../../common/UI/InputField/InputField";
import FormButton from "../../common/UI/FormButton/FormButton";
import { AttachmentIcon } from "../../../assets/Icons/Icons";
import { Form, Formik } from "formik";
import { Heading, SimpleGrid, useToast } from "@chakra-ui/react";
import { toastError } from "../../../lib/toastDetails";
import { taskInitialValues, taskSchema } from "./taskSchema";
import MultiUpload from "../../common/MultiUpload";
import { useUsers } from "../../../hooks/useUser";
import SelectInput from "./../../Membership/Allocations/AllocationForm/SelectInput";
import moment from "moment";
import { designationOptions } from "../../../lib/options";

// const Tags = [
//   { label: "Legal Aid", value: "legal aid" },
//   { label: "Court case", value: "court case" },
//   { label: "Court processors", value: "court processors" },
//   { label: "Court Process Server", value: "Court Process Server" },
//   { label: "CLV Coordinator", value: "CLV Coordinator" },
//   { label: "M & E", value: "M & E" },
//   { label: "General", value: "General" },
// ];

const TIME_FORMAT_DATETIME = "YYYY-MM-DDTHH:mm[:00]";

const NewTaskForm = ({ onSubmit, error, isError, isSubmitting }) => {
  const users = useUsers();
  const [files, setFiles] = useState([]);
  const toast = useToast();
  const [outline, setOutLine] = useState("");
  const [outlines, setOutLines] = useState([]);

  const outlineHandler = (e, setFieldValue) => {
    e.preventDefault();
    const outlinesCopy = [...outlines];
    outlinesCopy.push(outline);
    setOutLines(outlinesCopy);
    setFieldValue("outline", outlinesCopy);
    setOutLine("");
  };

  const removeOutlineHandler = (index, setFieldValue) => {
    const outlinesCopy = [...outlines];
    outlinesCopy.splice(index, 1);
    setOutLines(outlinesCopy);
    setFieldValue("outline", outlinesCopy);
  };

  useEffect(() => {
    if (isError) {
      toast(toastError(error));
    }
  }, [isError, error, toast]);

  const tags = designationOptions.map((item) => {
    return { label: item.label, value: item.label };
  });

  return (
    <Formik
      initialValues={taskInitialValues}
      validationSchema={taskSchema}
      onSubmit={(values) => {
        const formData = new FormData();
        
        // Append all form values
        Object.keys(values).forEach(key => {
          formData.append(key, values[key]);
        });
        
        // Append files
        files.forEach(file => {
          formData.append('files', file);
        });
        onSubmit(formData);
        
        setOutLine("");
        setOutLines([]);
      }}
    >
      {({ errors, touched, handleSubmit, values, setFieldValue }) => (
        <div className={classes.new_asset_wrapper}>
          <Form onSubmit={handleSubmit}>
            <div>
              <Heading fontSize="md" fontWeight="medium">
                Task Title
              </Heading>
              <InputField name="title" placeholder="Task Title" fullWidth />
            </div>
            <div>
              <Heading fontSize="md" fontWeight="medium" mt={5}>
                Task Description
              </Heading>
              <InputField
                name="description"
                type="text"
                placeholder="Task Description"
                fullWidth
              />
            </div>
            <SimpleGrid columns={2} spacingX="40px">
              <div>
                <Heading fontSize="md" fontWeight="medium" mt={5}>
                  Start Date
                </Heading>
                <InputField
                  name="startDate"
                  placeholder="End Date"
                  type="datetime-local"
                  min={moment().format(TIME_FORMAT_DATETIME)}
                  fullWidth
                />
              </div>
              <div>
                <Heading fontSize="md" fontWeight="medium" mt={5}>
                  Due Date
                </Heading>
                <InputField
                  name="endDate"
                  placeholder="End Date"
                  type="datetime-local"
                  min={
                    values?.startDate || moment().format(TIME_FORMAT_DATETIME)
                  }
                  fullWidth
                />
              </div>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              spacingX="20px"
              className={styles.asset_tags_team_wrapper}
            >
              <div className={styles.tag_wrapper}>
                <h6>
                  <AttachmentIcon /> Add Tags
                </h6>
                <div style={{ marginRight: 10 }}>
                  <SelectInput
                    noOptionsMessage={() => "No tags found"}
                    name="tags"
                    isMulti
                    onChange={(value) => {
                      setFieldValue(
                        "tags",
                        value.map((tag) => tag.value)
                      );
                    }}
                    options={tags}
                    placeholder="Select tags"
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor:
                          touched.tags && errors.tags ? "red" : "#ced4da",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className={styles.tag_wrapper}>
                <h6>
                  <AttachmentIcon />
                  Team
                </h6>
                <div style={{ marginLeft: 10 }}>
                  <SelectInput
                    name="team"
                    isMulti
                    onChange={(value) => {
                      setFieldValue(
                        "team",
                        value.map((team) => team.value)
                      );
                    }}
                    options={users.map((user) => {
                      return {
                        label: user?.name ? user.name : user.first_name,
                        value: user.id,
                      };
                    })}
                    placeholder="Select teams"
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor:
                          touched.team && errors.team ? "red" : "#ced4da",
                      }),
                    }}
                  />
                </div>
              </div>
            </SimpleGrid>
            <div className={styles.asset_outline_wrapper}>
              <h6>
                <AttachmentIcon /> Outlines
              </h6>
              <div className={styles.outline_list}>
                <ul>
                  {outlines.map((outline, idx) => {
                    return (
                      <li
                        key={outline}
                        onClick={() => removeOutlineHandler(idx, setFieldValue)}
                        title={`Click to remove "${outline}"`}
                        style={{ cursor: "pointer" }}
                      >
                        {outline}
                      </li>
                    );
                  })}
                </ul>
                <InputField
                  placeholder="Type outline and press enter or add button"
                  name="outline"
                  value={outline}
                  fullWidth={true}
                  onChange={(e) => {
                    setOutLine(e.target.value);
                  }}
                />
                <div style={{ float: "right" }}>
                  <FormButton
                    variant="colored"
                    rounded={true}
                    onClick={(e) => outlineHandler(e, setFieldValue)}
                  >
                    + Add
                  </FormButton>
                </div>
              </div>
            </div>
            <div className={classes.asset_attachement_wrapper}>
              <h6>
                <AttachmentIcon /> Attachment
              </h6>
              <MultiUpload files={files} setFiles={setFiles} />
            </div>
            <div style={{ float: "right", padding: "20px 0" }}>
              <FormButton
                variant="colored"
                rounded={true}
                disabled={
                  isSubmitting || !values.tags.length || !values.team.length
                }
                type="submit"
              >
                {isSubmitting ? "Adding..." : "Add Task"}
              </FormButton>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default NewTaskForm;
