import React from 'react';

export const ArrowLeft = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
    <g
      id='vuesax_linear_arrow-left'
      data-name='vuesax/linear/arrow-left'
      transform='translate(-364 -252)'
    >
      <g id='arrow-left' transform='translate(364 252)'>
        <path
          id='Vector'
          d='M4.732,10.56.385,6.213a1.324,1.324,0,0,1,0-1.867L4.732,0'
          transform='translate(5.268 2.72)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          id='Vector-2'
          data-name='Vector'
          d='M0,0H16V16H0Z'
          transform='translate(16 16) rotate(180)'
          fill='none'
          opacity='0'
        />
      </g>
    </g>
  </svg>
);

export const ArrowRight = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
    <g
      id='vuesax_linear_arrow-right'
      data-name='vuesax/linear/arrow-right'
      transform='translate(-300 -252)'
    >
      <g id='arrow-right' transform='translate(300 252)'>
        <path
          id='Vector'
          d='M0,10.56,4.347,6.213a1.324,1.324,0,0,0,0-1.867L0,0'
          transform='translate(5.94 2.72)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          id='Vector-2'
          data-name='Vector'
          d='M0,0H16V16H0Z'
          transform='translate(16 16) rotate(180)'
          fill='none'
          opacity='0'
        />
      </g>
    </g>
  </svg>
);
