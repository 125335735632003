import axiosClient from "../lib/axiosClient";
import { routes } from "../lib/routes";

export const getMe = async () => {
  return await axiosClient.get(routes.users.getMe);
};

export const logIn = async (values) => {
  return await axiosClient.post(routes.users.login, values);
};

export const forgotPassword = async (email) => {
  return await axiosClient.post(routes.users.forgotPassword, { email });
};
export const setPasswordLink = async (email) => {
  return await axiosClient.post(routes.users.requestPasswordResetLink, {
    email,
  });
};

export const signUp = async (values) => {
  return await axiosClient.post(routes.users.signUp, values);
};

export const setPassword = async (values) => {
  return await axiosClient.patch(
    `${routes.users.setPassword}/${values.token}`,
    values
  );
};

export const getAllDeactivatedUsers = async () => {
  return await axiosClient.get(routes.users.deactivatedUsers);
};

export const getAllActivatedUsers = async () => {
  return await axiosClient.get(routes.users.activatedUsers);
};

export const activateUser = async (id) => {
  return await axiosClient.post(routes.users.activateUser, id);
};

export const requestPasswordLink = async (id) => {
  return await axiosClient.post(routes.users.requestPasswordLink, id);
};

export const updateProfile = async (values) => {
  return await axiosClient.patch(routes.users.updateProfile, values);
};

export const getAllDistricts = async () => {
  return await axiosClient.get(routes.users.getAllDistricts);
};

export const getAllCounties = async () => {
  return await axiosClient.get(routes.users.getAllCounties);
};

export const getAllParishes = async () => {
  return await axiosClient.get(routes.users.getAllParishes);
};

export const getAllVillages = async () => {
  return await axiosClient.get(routes.users.getAllVillages);
};

export const getAllSubCounties = async () => {
  return await axiosClient.get(routes.users.getAllSubCounties);
};

export const getDistrictSubCounties = async (districtId) => {
  return await axiosClient.get(
    `${routes.registration.getDistrictSubCounty}/${districtId}`
  );
};

export const getDistrictCounties = async (districtId) => {
  return await axiosClient.get(`${routes.users.getCountiesByDistrict}/${districtId}`);
};

export const getCountySubcounties = async (countyId) => {
  return await axiosClient.get(`${routes.users.getSubcountiesByCounty}/${countyId}`);
};

export const getSubcountyParishes = async (parishId) => {
  return await axiosClient.get(`${routes.users.getParishesBySubcounty}/${parishId}`);
};

export const getParisheVillages = async (parishId) => {
  return await axiosClient.get(`${routes.users.getVillagesByParish}/${parishId}`);
};

export const getLocationNames = async (locationIds) => {
  return await axiosClient.get(routes.users.getLocationNames, { params: locationIds });
};
