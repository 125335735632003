import React, { useEffect, useState } from "react";
import SectionHeader from "../../common/SectionHeader";
import Modal from "../../common/Modal";
import { useDisclosure } from "@chakra-ui/react";
import NewContract from "./NewContract/NewContract";
import Loader from "./../../common/UI/Loader/Loader";
import { useActivatedUsers } from "../../../hooks/useUser";
import { approvalTableColumns } from "../../../lib/tableColumns";
import Table from "../../common/TableComponent/Table";

const Employees = () => {
  const { isOpen, onClose } = useDisclosure();
  const { data: activatedUsersData, isLoading } = useActivatedUsers();
  const [data, setData] = useState([]);
  useEffect(() => {
    setData([]);
    if (activatedUsersData?.users?.length) {
      const dataToSet = activatedUsersData?.users?.map((b) => {
        return {
          ...b,
          key: b?.id,
          full_name: b?.full_name ? b.full_name : "N/A",
        };
      });
      setData(dataToSet);
    }
  }, [activatedUsersData]);
  return (
    <>
      <SectionHeader title="Employees" />
      {isLoading ? (
        <Loader />
      ) : (
        data && (
          <Table
            isLoading={isLoading}
            data={data ? data : null}
            showBtn={false}
            tableName="Employees"
            columns={approvalTableColumns}
            hideActions
          />
        )
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <NewContract />
      </Modal>
    </>
  );
};

export default Employees;
