import React from "react";
import "./Pagination.css";
import { ArrowLeft, ArrowRight } from "./Icons";
import { usePagination, DOTS } from "../../../hooks/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`pagination-container ${className}`}>
      <li
        className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
        role="presentation"
        onClick={onPrevious}
      >
        <div className="arrow left">
          <ArrowLeft />
        </div>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={pageNumber}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={`pagination-item ${
              pageNumber === currentPage ? "selected" : ""
            }`}
            onClick={() => onPageChange(pageNumber)}
            key={pageNumber}
            role="presentation"
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item ${
          currentPage === lastPage ? "disabled" : ""
        }`}
        role="presentation"
        onClick={onNext}
      >
        <div className="arrow right">
          <ArrowRight />
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
