import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select as SelectBase,
  Spinner,
} from "@chakra-ui/react";
import { Field } from "formik";

const SelectField = ({ label, name, options, isLoading, error, ...rest }) => {
  return (
    <Field name={name} style={{ width: "100%" }}>
      {({ field, form }) => (
        <FormControl>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          {isLoading ? (
            <Spinner size="md" />
          ) : error ? (
            <FormErrorMessage>{error}</FormErrorMessage>
          ) : (
            <SelectBase id={name} {...field} {...rest} bgColor="white">
              {options && options.length > 0 ? (
                options?.map((option) => (
                  <option
                    key={option.value}
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    style={{ textOverflow: "ellipsis", maxWidth: "100%" }}
                  >
                    {option.label}
                  </option>
                ))
              ) : (
                <option value="">No options available</option>
              )}
            </SelectBase>
          )}
          {form.errors[name] && (
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default SelectField;
