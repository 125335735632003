import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import SectionHeader from "../common/SectionHeader";
import Modal from "../common/Modal";
import RequisitionForm from "../forms/requisition/RequisitionForm";
import {
  useAddRequisition,
  useApprovedAccountantRequisitions,
  useApprovedDopRequisitions,
  useApprovedHofRequisitions,
  useApprovedProcurementRequisitions,
  useApprovedSeniorAccountantRequisitions,
  useMyRequisitions,
  usePendingAccountantRequisitions,
  usePendingDopRequisitions,
  usePendingProgramsManagerRequisitions,
  usePendingHofRequisitions,
  usePendingProcurementRequisitions,
  usePendingSeniorAccountantRequisitions,
  useRejectedAccountantRequisitions,
  useRejectedDopRequisitions,
  useRejectedHofRequisitions,
  useRejectedProcurementRequisitions,
  useRejectedSeniorAccountantRequisitions,
  useApprovedProgramsManagerRequisitions,
  useRejectedProgramsManagerRequisitions
} from "../../hooks/useRequisitions";

import SubHeading from "./../Tasks/SubHeading/SubHeading";
// import RequisitionTable from "../dashboard/Requisitions/RequisitionsTable";
import {
  requisitionInitialValues,
  requisitionSchema,
} from "../forms/requisition/schemas/requisitions";
import Loader from "../common/UI/Loader/Loader";
import { useSelector } from "react-redux";
import Table from "../common/TableComponent/Table";
import { requisitionRequestsTableColumns } from "../../lib/tableColumns";
import { useNavigate } from "react-router-dom";
import { useProjectOptions } from "../../hooks/useProjects";

const Requisitions = () => {
  //get user and designation
  const { user } = useSelector((state) => state.auth);
  // console.log(user)
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {data, isLoading} = useRequisitions();
  const navigate = useNavigate();
  const handleViewSummary = (id) => {
    navigate(`/requisitions/${id.id}`);
  };

  const { data: myRequisitions, isLoading } = useMyRequisitions();
  // console.log(myRequisitions)

  const { data: pendingProgramsManagerRequisitions } = usePendingProgramsManagerRequisitions();
  const { data: approvedProgramsManagerRequisitions } = useApprovedProgramsManagerRequisitions();
  const { data: rejectedProgramsManagerRequisitions } = useRejectedProgramsManagerRequisitions();
  const { data: rejectedDopRequisitions } = useRejectedDopRequisitions();

  const { data: pendingHofRequisitions } = usePendingHofRequisitions();
  const { data: approvedHofRequisitions } = useApprovedHofRequisitions();
  const { data: rejectedHofRequisitions } = useRejectedHofRequisitions();
console.log('pendingProgramsManagerRequisitions', pendingProgramsManagerRequisitions)
  const { data: pendingSeniorAccountantRequisitions } =
    usePendingSeniorAccountantRequisitions();
  const { data: approvedSeniorAccountantRequisitions } =
    useApprovedSeniorAccountantRequisitions();
  const { data: rejectedSeniorAccountantRequisitions } =
    useRejectedSeniorAccountantRequisitions();

  const { data: pendingAccountantRequisitions } =
    usePendingAccountantRequisitions();
  const { data: approvedAccountantRequisitions } =
    useApprovedAccountantRequisitions();
  const { data: rejectedAccountantRequisitions } =
    useRejectedAccountantRequisitions();

  const { data: pendingProcurementRequisitions } =
    usePendingProcurementRequisitions();
  const { data: approvedProcurementRequisitions } =
    useApprovedProcurementRequisitions();
  const { data: rejectedProcurementRequisitions } =
    useRejectedProcurementRequisitions();

  const [userData, setUserData] = useState([]);
  const [rejectedDop, setRejectedDop] = useState([]);

  const [pendingProgramsManager, setPendingProgramsManager] = useState([]);
  const [approvedProgramsManager, setApprovedProgramsManager] = useState([]);
  const [rejectedProgramsManager, setRejectedProgramsManager] = useState([]);

  const [pendingAccountant, setPendingAccountant] = useState([]);
  const [approvedAccountant, setApprovedAccountant] = useState([]);
  const [rejectedAccountant, setRejectedAccountant] = useState([]);

  const [pendingSeniorAccountant, setPendingSeniorAccountant] = useState([]);
  const [approvedSeniorAccountant, setApprovedSeniorAccountant] = useState([]);
  const [rejectedSeniorAccountant, setRejectedSeniorAccountant] = useState([]);

  const [pendingFinance, setPendingFinance] = useState([]);
  const [approvedFinance, setApprovedFinance] = useState([]);
  const [rejectedFinance, setRejectedFinance] = useState([]);

  const [pendingProcurement, setPendingProcurement] = useState([]);
  const [approvedProcurement, setApprovedProcurement] = useState([]);
  const [rejectedProcurement, setRejectedProcurement] = useState([]);

  const projectOptions = useProjectOptions();

  //set table data for other users
  useEffect(() => {
    setUserData([]);
    if (myRequisitions?.requisitions?.length) {
      const dataToSet = myRequisitions?.requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
              // ? "Dop"
              // : b?.type === "activity" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.DOPApprovalStatus === "rejected"
              // ? "Dop"
              // : b?.type === "activity" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.DOPApprovalStatus === "approved"
              // ? " Project Accountant"
              // : b?.type === "activity" &&
              //   b?.approval_levels.length === 2 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "activity" &&
              //   b?.approval_levels.length === 2 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Programs Manager"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.procurementApprovalStatus === "approved"
              ? "Senior Accountant"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              // ? "Dop"
              // : b?.type === "procurement" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.DOPApprovalStatus === "rejected"
              // ? "Dop"
              // : b?.type === "procurement" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.DOPApprovalStatus === "approved"
              // ? "Project Accountant"
              // : b?.type === "procurement" &&
              //   b?.approval_levels.length === 2 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "procurement" &&
              //   b?.approval_levels.length === 2 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",

          status:
            b?.type === "activity" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setUserData(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRequisitions]);

  //set table data for pending finance requests
  useEffect(() => {
    setPendingFinance([]);
    if (pendingHofRequisitions?.Requisitions?.length) {
      const dataToSet = pendingHofRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 3 &&
              //   b?.seniorAccountantApprovalStatus === "rejected"
              // ? b?.seniorAccountantApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setPendingFinance(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingHofRequisitions]);

  //set table data for approved Hof requests
  useEffect(() => {
    setApprovedFinance([]);
    if (approvedHofRequisitions?.Requisitions?.length) {
      const dataToSet = approvedHofRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 2 &&
              //   b?.seniorAccountantApprovalStatus === "rejected"
              // ? b?.seniorAccountantApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setApprovedFinance(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedHofRequisitions]);

  //set table data for rejected Hof requests
  useEffect(() => {
    setRejectedFinance([]);
    if (rejectedHofRequisitions?.Requisitions?.length) {
      const dataToSet = rejectedHofRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? "Project Accountant"
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "rejected"
              // ? b?.accountantApprovalStatus
              // : b?.type === "admin" &&
              //   b?.approval_levels.length === 1 &&
              //   b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setRejectedFinance(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedHofRequisitions]);

  //set table data for approved Accountant requests
  useEffect(() => {
    setApprovedAccountant([]);
    if (approvedAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = approvedAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? " Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setApprovedAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedAccountantRequisitions]);

  //set table data for pending Accountant  requests
  useEffect(() => {
    setPendingAccountant([]);
    if (pendingAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = pendingAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? " Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setPendingAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingAccountantRequisitions]);

  //set table data for rejected Accountant requests
  useEffect(() => {
    setRejectedAccountant([]);
    if (rejectedAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = rejectedAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setRejectedAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedAccountantRequisitions]);

  //set table data for pending procurement requests
  useEffect(() => {
    setPendingProcurement([]);
    if (pendingProcurementRequisitions?.Requisitions?.length) {
      const dataToSet = pendingProcurementRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? " Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Procurement"
                : "Procurement",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.procurementApprovalStatus,
          };
        }
      );
      setPendingProcurement(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingProcurementRequisitions]);

  //set table data for approved procurement requests
  useEffect(() => {
    setApprovedProcurement([]);
    if (approvedProcurementRequisitions?.Requisitions?.length) {
      const dataToSet = approvedProcurementRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? " Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Procurement"
                : "Procurement",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.procurementApprovalStatus,
          };
        }
      );
      setApprovedProcurement(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedProcurementRequisitions]);

  //set table data for rejected procurement requests
  useEffect(() => {
    setRejectedProcurement([]);
    if (rejectedProcurementRequisitions?.Requisitions?.length) {
      const dataToSet = rejectedProcurementRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? " Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ?
                //  "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Procurement"
                : "Procurement",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "activity" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "admin" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? b?.DOPApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? b?.accountantApprovalStatus
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.procurementApprovalStatus,
          };
        }
      );
      setRejectedProcurement(dataToSet);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedProcurementRequisitions]);

  //set table data for pending Dop requests
  useEffect(() => {
    setPendingProgramsManager([]);
    if (pendingProgramsManagerRequisitions?.Requisitions?.length) {
      const dataToSet = pendingProgramsManagerRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Senior Accountant"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.seniorAccountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 3 &&
                b?.seniorAccountantApprovalStatus === "rejected"
              ? b?.seniorAccountantApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setPendingProgramsManager(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingProgramsManagerRequisitions]);

  //set table data for approved Dop requests
  useEffect(() => {
    setApprovedProgramsManager([]);
    if (approvedProgramsManagerRequisitions?.Requisitions?.length) {
      const dataToSet = approvedProgramsManagerRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0            
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? "Programs Manager"
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? "Finance"
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? "Finance"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Senior Accountant"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? "Programs Manager"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "approved"
              ? "Procurement"
              : "Procurement",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "rejected"
            ? b?.programsManagerApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 1 &&
              b?.programsManagerApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "rejected"
            ? b?.HOFApprovalStatus
            : b?.type === "activity" &&
              b?.approval_levels.length === 2 &&
              b?.HOFApprovalStatus === "approved"
            ? b?.HOFApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.seniorAccountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 3 &&
                b?.seniorAccountantApprovalStatus === "rejected"
              ? b?.seniorAccountantApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "rejected"
              ? b?.programsManagerApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.programsManagerApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.procurementApprovalStatus
        };
      });
      setApprovedProgramsManager(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedProgramsManagerRequisitions]);

  //set table data for rejected Dop requests
  useEffect(() => {
    setRejectedProgramsManager([]);
    if (rejectedProgramsManagerRequisitions?.Requisitions?.length) {
      const dataToSet = rejectedProgramsManagerRequisitions?.Requisitions?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name
            ? b.createdBy.full_name
            : b?.registeredBy?.full_name
            ? b.registeredBy.full_name
            : "N/A",
          project_name: projectOptions.find(
            (project) => project.value === b?.project_name
          )?.label,
          stage:
            b?.type === "activity" && b?.approval_levels.length === 0
              ? "Dop"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "rejected"
              ? "Dop"
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "approved"
              ? " Project Accountant"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "rejected"
              ? "Project Accountant"
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "activity" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "approved"
              ? "Senior Accountant"
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? "Project Accountant"
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? "Senior Accountant"
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? "Dop"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "rejected"
              ? "Dop"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "approved"
              ? "Project Accountant"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "rejected"
              ? "Project Accountant"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "approved"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "rejected"
              ? "Finance"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "approved"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 4 &&
                b?.procurementApprovalStatus === "rejected"
              ? "Procurement"
              : b?.type === "procurement" &&
                b?.approval_levels.length === 4 &&
                b?.procurementApprovalStatus === "approved"
              ? "Senior Accountant"
              : "Senior Accountant",
          status:
            b?.type === "activity" && b?.approval_levels.length === 0
              ? b?.DOPApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "rejected"
              ? b?.DOPApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "approved"
              ? b?.accountantApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "rejected"
              ? b?.accountantApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "activity" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.seniorAccountantApprovalStatus
              : // admin requisitions
              b?.type === "admin" && b?.approval_levels.length === 0
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "rejected"
              ? b?.accountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 1 &&
                b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 2 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.seniorAccountantApprovalStatus
              : b?.type === "admin" &&
                b?.approval_levels.length === 3 &&
                b?.seniorAccountantApprovalStatus === "rejected"
              ? b?.seniorAccountantApprovalStatus
              : // procurement requisitions
              b?.type === "procurement" && b?.approval_levels.length === 0
              ? b?.DOPApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "rejected"
              ? b?.DOPApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 1 &&
                b?.DOPApprovalStatus === "approved"
              ? b?.accountantApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "rejected"
              ? b?.accountantApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 2 &&
                b?.accountantApprovalStatus === "approved"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "rejected"
              ? b?.HOFApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 3 &&
                b?.HOFApprovalStatus === "approved"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 4 &&
                b?.procurementApprovalStatus === "rejected"
              ? b?.procurementApprovalStatus
              : b?.type === "procurement" &&
                b?.approval_levels.length === 4 &&
                b?.procurementApprovalStatus === "approved"
              ? b?.seniorAccountantApprovalStatus
              : b?.seniorAccountantApprovalStatus,
        };
      });
      setRejectedProgramsManager(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedProgramsManagerRequisitions]);

  //set table data for pending Senior Accountant requests
  useEffect(() => {
    setPendingSeniorAccountant([]);
    if (pendingSeniorAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = pendingSeniorAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? " Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                // b?.type === "procurement" && b?.approval_levels.length === 0
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "rejected"
                // ? "Dop"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 1 &&
                //   b?.DOPApprovalStatus === "approved"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "rejected"
                // ? "Project Accountant"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 2 &&
                //   b?.accountantApprovalStatus === "approved"
                // ? "Finance"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 3 &&
                //   b?.HOFApprovalStatus === "rejected"
                // ? "Finance"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 3 &&
                //   b?.HOFApprovalStatus === "approved"
                // ? "Procurement"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 4 &&
                //   b?.procurementApprovalStatus === "rejected"
                // ? "Procurement"
                // : b?.type === "procurement" &&
                //   b?.approval_levels.length === 4 &&
                //   b?.procurementApprovalStatus === "approved"
                // ? "Senior Accountant"
                // : "Senior Accountant",
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setPendingSeniorAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingSeniorAccountantRequisitions]);

  //set table data for approved Senior Accountant requests
  useEffect(() => {
    setApprovedSeniorAccountant([]);
    if (approvedSeniorAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = approvedSeniorAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? " Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setApprovedSeniorAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedSeniorAccountantRequisitions]);

  //set table data for rejected Senior Accountant requests
  useEffect(() => {
    setRejectedSeniorAccountant([]);
    if (rejectedSeniorAccountantRequisitions?.Requisitions?.length) {
      const dataToSet = rejectedSeniorAccountantRequisitions?.Requisitions?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name
              ? b.createdBy.full_name
              : b?.registeredBy?.full_name
              ? b.registeredBy.full_name
              : "N/A",
            project_name: projectOptions.find(
              (project) => project.value === b?.project_name
            )?.label,
            stage:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? " Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Senior Accountant"
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? "Dop"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? "Project Accountant"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? "Finance"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? "Procurement"
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? "Senior Accountant"
                : "Senior Accountant",
            status:
              b?.type === "activity" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "activity" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : // admin requisitions
                b?.type === "admin" && b?.approval_levels.length === 0
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 1 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 2 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.type === "admin" &&
                  b?.approval_levels.length === 3 &&
                  b?.seniorAccountantApprovalStatus === "rejected"
                ? b?.seniorAccountantApprovalStatus
                : // procurement requisitions
                b?.type === "procurement" && b?.approval_levels.length === 0
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "rejected"
                ? b?.DOPApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 1 &&
                  b?.DOPApprovalStatus === "approved"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "rejected"
                ? b?.accountantApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 2 &&
                  b?.accountantApprovalStatus === "approved"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "rejected"
                ? b?.HOFApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 3 &&
                  b?.HOFApprovalStatus === "approved"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "rejected"
                ? b?.procurementApprovalStatus
                : b?.type === "procurement" &&
                  b?.approval_levels.length === 4 &&
                  b?.procurementApprovalStatus === "approved"
                ? b?.seniorAccountantApprovalStatus
                : b?.seniorAccountantApprovalStatus,
          };
        }
      );
      setRejectedSeniorAccountant(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedSeniorAccountantRequisitions]);

  return (
    <>
      <SectionHeader title="Requisitions" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {user.designation === "dop" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              {/* <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingDop.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread Dop Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedDop.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Dop Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedDop.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Dop Requisitions requests"
              /> */}
            </>
          )}

          {user.designation === "finance" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingFinance.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread HOF Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedFinance.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved HOF Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedFinance.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected HOF Requisitions requests"
              />
            </>
          )}
          {user.designation === "accountant" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              <br />
              {/* <SubHeading title="Unread Requests" />
              <Table
                data={pendingAccountant.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread Accountant Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedAccountant.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Accountant Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedAccountant.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Accountant Requisitions requests"
              /> */}
            </>
          )}

          {user.designation === "procurement" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingProcurement.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread Procurement Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedProcurement.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Procurement Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedProcurement.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Procurement Requisitions requests"
              />
            </>
          )}

{user.designation === "programsManager" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingProgramsManager.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread Programs Manager Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedProgramsManager.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Programs Manager Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedProgramsManager.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Programs Manager Requisitions requests"
              />
            </>
          )}


          {user.designation === "seniorAccountant" && (
            <>
              <SubHeading title="My Requisition Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                btnLabel="Add Requisition"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="My Requisitions requests"
              />
              <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingSeniorAccountant}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Unread Senior Accountant Requisitions requests"
              />
              <br />
              <SubHeading title="Approved Requisitions" />
              <Table
                data={approvedSeniorAccountant.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Senior Accountant Requisitions requests"
              />
              <br />
              <SubHeading title="Rejected Requisitions" />
              <Table
                data={rejectedSeniorAccountant.slice().reverse()}
                columns={requisitionRequestsTableColumns}
                loading={isLoading}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Senior Accountant Requisitions requests"
              />
            </>
          )}

          {user.designation !== "accountant" &&
            user.designation !== "finance" &&
            user.designation !== "seniorAccountant" &&
            user.designation !== "dop" &&
            user.designation !== "programsManager" &&
            user.designation !== "procurement" && (
              <>
                <SubHeading title="My Requisition Requests" />
                <Table
                  data={userData.slice().reverse()}
                  columns={requisitionRequestsTableColumns}
                  loading={isLoading}
                  btnLabel="Add Requisition"
                  btnClick={onOpen}
                  showActions={true}
                  onViewHandler={handleViewSummary}
                  tableName="My Requisitions requests"
                />
              </>
            )}
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose} title="Requisition" size="xl">
        <RequisitionForm
          initialValues={requisitionInitialValues}
          validationSchema={requisitionSchema}
          onSuccess={onClose}
          success={`Requisition added successfully`}
          useMutate={useAddRequisition}
        />
      </Modal>
    </>
  );
};

export default Requisitions;
