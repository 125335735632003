export const designationOptions = [
  { label: "CLV", value: "clv" },
  { label: "Court Process Server", value: "courtProcessServer" },
  { label: "Legal Aid", value: "legalAid" },
  { label: "Membership", value: "membership" },
  { label: "Human Resources", value: "humanResources" },
  { label: "IT", value: "it" },
  { label: "Finance", value: "finance" },
  { label: "M & E", value: "me" },
  { label: "Programs manager", value: "programsManager" },
  { label: "Director of Programs", value: "dop" },
  { label: "Procurement", value: "procurement" },
  { label: "Counselor", value: "counselor" },
  { label: "Fleet Manager", value: "fleetManager" },
  { label: "Accountant", value: "accountant" },
  { label: "Senior Accountant", value: "seniorAccountant" },
  { label: "Transport Assistant", value: "transportAssistant" },
  { label: "HR Consultant", value: "hrConsultant" },
  { label: "Legal Clerk", value: "legalClerk" },
  { label: "Communications Officer", value: "communicationsOfficer" },
  { label: "Projects Coordinator", value: "projectsCoordinator" },
  { label: "CEO", value: "ceo" },
  { label: "Other", value: "other" },
  { label: "Senior Monitoring & Evaluation Officer", value: "seniorMeOfficer" },
  { label: "Monitoring & Evaluation Officer", value: "meOfficer" },
  { label: "Legal Officer", value: "legalOfficer" },
  { label: "Legal Assistant", value: "legalAssistant" },
  { label: "Communications Assistant", value: "communicationsAssistant" },
  { label: "Manager of Advocacy", value: "managerOfAdvocacy" },
  { label: "Legal Associate", value: "legalAssociate" },
  { label: "Strategic Litigation Officer & Board Liaison", value: "strategicLitigationBoardLiaison" },
  { label: "COO & Client Liaison", value: "cooClientLiaison" },
  { label: "Procurement Officer", value: "procurementOfficer" },
  { label: "Manager of Finance", value: "managerOfFinance" },
  { label: "Director of Finance & Administration", value: "directorOfFinanceAndAdmin" },
  { label: "Accounts Assistant", value: "accountsAssistant" },
  { label: "Project Accountant", value: "projectAccountant" },
  { label: "Front Desk & Admin Assistant", value: "frontDeskAdminAssistant" },
  { label: "Office Assistant", value: "officeAssistant" },
  { label: "Programs Advisor", value: "programsAdvisor" },
  { label: "Security Officer", value: "securityOfficer" },
  { label: "Strategic Litigation", value: "strategicLitigation" },
  { label: "Manager of Fundraising & Research", value: "managerOfFundraisingResearch" },
  { label: "Manager Monitoring & Evaluation", value: "managerMe" },
  { label: "Chef/Admin Assistant", value: "chefAdminAssistant" },
  { label: "Programs Consultant", value: "programsConsultant" },
  { label: "Project Officer", value: "projectOfficer" },
  { label: "Program Assistant", value: "programAssistant" }
];

export const maritalStatusOptions = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
  { label: "Cohabiting", value: "cohabiting" },
  { label: "Separated", value: "separated" },
  { label: "Other", value: "other" },
];

export const educationLevelOptions = [
  { label: "Degree", value: "degree" },
  { label: "Diploma", value: "diploma" },
  { label: "Certificate", value: "certificate" },
  { label: "UACE", value: "uace" },
  { label: "UCE", value: "uce" },
  { label: "Primary", value: "primary" },
  { label: "No education", value: "noEducation" },
]

export const FIDA_clinic_locations = [
  { label: "KAMPALA MAIN CLINIC", value: "kampala main clinic" },
  { label: "LUWERO", value: "luwero" },
  { label: "LIRA", value: "lira" },
  { label: "GULU", value: "gulu" },
  { label: "NEBBI", value: "nebbi" },
  { label: "ARUA", value: "arua" },
  { label: "MOROTO", value: "moroto" },
  { label: "KOTIDO", value: "kotido" },
  { label: "NAPAK", value: "napak" },
  { label: "KAABONG", value: "kaabong" },
  { label: "KARENGA", value: "karenga" },
  { label: "MBALE", value: "mbale" },
  { label: "IGANGA", value: "iganga" },
  { label: "KAMULI", value: "kamuli" },
  { label: "LAMWO", value: "lamwo" },
  { label: "LWENGO", value: "lwengo" },
  { label: "LUWERO JUSTICE DESK", value: "luwero justice desk" },
  { label: "KABALE", value: "kabale" },
  { label: "FORTPORTAL", value: "fortportal" },
  { label: "NSANGI JUSTICE DESK", value: "nsangi justice desk" },
  {
    label: "KASANGATI JUSTICE DESK",
    value: "kasangati justice desk",
  },
  {
    label: "MAKINDYE JUSTICE DESK",
    value: "makindye justice desk",
  },
  { label: "NABWERU JUSTICE DESK", value: "nabweru justice desk" },
];

export const sexOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const requisitionTypeOptions = [
  { label: "Activity Requisition", value: "activity" },
  { label: "Admin Requisition", value: "admin" },
  { label: "Procurement Requisition", value: "procurement" },
];

export const yesNoOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const trueFalseOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const clientStaffOptions = [
  { label: "Staff", value: "staff" },
  { label: "Client", value: "client" },
];

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const reportTypeOptions = [
  { label: "Activity Report", value: "Activity Report" },
  { label: "Monthly Report", value: "Monthly Report" },
  { label: "Quarterly Report", value: "Quarterly Report" },
  { label: "Bi-annual Report", value: "Bi-annual Report" },
  { label: "Annual Report", value: "Annual Report" },
  { label: "Project Report", value: "Project Report" },
];

export const assetTypeOptions = [
  { label: "IT Asset", value: "IT Asset" },
  { label: "Office Furniture", value: "Office Furniture" },
  { label: "Auto Mobile (Vehicle/Bike)", value: "Auto Mobile (Vehicle/Bike)" },
  {
    label: "Visibility Materials (Shirts/Jackets)",
    value: "Visibility Materials (Shirts/Jackets)",
  },
];

export const disabilityOptions = [
  { label: "No - No difficulty", value: "No" },
  { label: "Yes - Some difficulty", value: "Kinda" },
  { label: "Yes - A lot of difficulty", value: "Mostly" },
  { label: "Cannot do at all", value: "Yes" },
];

export const monthsOptions = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const caseFileStatusOptions = [
  {
    value: "Concluded through ADR/elders",
    label: "Concluded through ADR/elders",
  },
  {
    label: "Resolved by Legal Aid/Counselling",
    value: "Resolved by Legal Aid/Counselling",
  },
  { label: "Resolved by Legal Advice", value: "Resolved by Legal Advice" },
  {
    label: "Concluded through Mediation",
    value: "Concluded through Mediation",
  },
  { label: "Pending Mediation", value: "Pending Mediation" },
  { label: "Pending in Court", value: "Pending in Court" },
];
