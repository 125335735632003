import { useDisclosure } from "@chakra-ui/react";
import {
  useAddTravelOrder,
  useApprovedFleetManagerTravelOrders,
  useMyTravelOrders,
  usePendingFleetManagerTravelOrders,
  useRejectedFleetManagerTravelOrders,
} from "../../hooks/useTravelOrders";
import SectionHeader from "../common/SectionHeader";
import Modal from "../common/Modal";
import TravelOrderForm from "../forms/travelOrder/TravelOrderForm";
// import TravelOrderTable from "../dashboard/TravelOrder/TravelOrderTable";
import SubHeading from "./../Tasks/SubHeading/SubHeading";
import Loader from "../common/UI/Loader/Loader";
import {
  travelOrderInitialValues,
  travelOrderSchema,
} from "../forms/travelOrder/schemas/travelOrder";
import { useSelector } from "react-redux";
import Table from "../common/TableComponent/Table";
import { travelOrdersTableColumns } from "../../lib/tableColumns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectOptions } from "../../hooks/useProjects";

const TravelOrder = () => {
  const navigate = useNavigate();
  const projectOptions = useProjectOptions();
  //get user and designation
  const { user } = useSelector((state) => state.auth);

  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {  isLoading } = useTravelOrders();
  const { data: myTravelOrders, isLoading } = useMyTravelOrders();
  // console.log(myTravelOrders)

  const { data: pendingFleetManagerTravelOrders, isLoading: fleetPending } =
    usePendingFleetManagerTravelOrders();
  const { data: approvedFleetManagerTravelOrders, isLoading: fleetApproved } =
    useApprovedFleetManagerTravelOrders();
  const { data: rejectedFleetManagerTravelOrders, isLoading: fleetRejected } =
    useRejectedFleetManagerTravelOrders();

  const [userData, setUserData] = useState([]);
  const [pendingFleetData, setPendingFleetData] = useState([]);
  const [approvedFleetData, setApprovedFleetData] = useState([]);
  const [rejectedFleetData, setRejectedFleetData] = useState([]);

  //set table data for other users
  useEffect(() => {
    setUserData([]);
    if (myTravelOrders?.travelOrders?.length) {
      const dataToSet = myTravelOrders?.travelOrders?.map((b) => {
        return {
          ...b,
          full_name: b?.createdBy?.full_name ? b.createdBy.full_name : "N/A",
          project: projectOptions.find((project) => project.value === b.project)
            ?.label,
          stage: "Fleet  Manager",
          status: b?.fleetManagerApprovalStatus,
        };
      });
      setUserData(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTravelOrders]);

  //set table data for pending Fleet manager table
  useEffect(() => {
    setPendingFleetData([]);
    if (pendingFleetManagerTravelOrders?.TravelOrders?.length) {
      const dataToSet = pendingFleetManagerTravelOrders?.TravelOrders?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name ? b.createdBy.full_name : "N/A",
            project: projectOptions.find(
              (project) => project.value === b.project
            )?.label,
              stage: "Fleet  Manager",
              status: b?.fleetManagerApprovalStatus,
          };
        }
      );
      setPendingFleetData(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFleetManagerTravelOrders]);

  //set table data for Approved fleet manager table
  useEffect(() => {
    setApprovedFleetData([]);
    if (approvedFleetManagerTravelOrders?.TravelOrders?.length) {
      const dataToSet = approvedFleetManagerTravelOrders?.TravelOrders?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name ? b.createdBy.full_name : "N/A",
            project: projectOptions.find(
              (project) => project.value === b.project
            )?.label,
              stage: "Fleet  Manager",
              status: b?.fleetManagerApprovalStatus,
          };
        }
      );
      setApprovedFleetData(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedFleetManagerTravelOrders]);

  //set table data for rejected fleet manager table
  useEffect(() => {
    setRejectedFleetData([]);
    if (rejectedFleetManagerTravelOrders?.TravelOrders?.length) {
      const dataToSet = rejectedFleetManagerTravelOrders?.TravelOrders?.map(
        (b) => {
          return {
            ...b,
            full_name: b?.createdBy?.full_name ? b.createdBy.full_name : "N/A",
            project: projectOptions.find(
              (project) => project.value === b.project
            )?.label,
              stage: "Fleet  Manager",
              status: b?.fleetManagerApprovalStatus,
          };
        }
      );
      setRejectedFleetData(dataToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedFleetManagerTravelOrders]);

  const handleViewSummary = (id) => {
    navigate(`/travel-order/${id.id}`);
  };

  return (
    <>
      <SectionHeader title="Travel Order" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {user.designation === "fleetManager" && (
            <>
              <SubHeading title="My Travel Requests" />
              <Table
                data={userData.slice().reverse()}
                columns={travelOrdersTableColumns}
                loading={isLoading}
                btnLabel="Add Travel Order"
                btnClick={onOpen}
                showActions={true}
                onViewHandler={handleViewSummary}
              />
              <br />
              <SubHeading title="Unread Requests" />
              <Table
                data={pendingFleetData}
                columns={travelOrdersTableColumns}
                loading={fleetPending}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Pending Fleet Manager travel requests"
              />
              <br />
              <SubHeading title="Approved Request" />
              <Table
                data={approvedFleetData}
                columns={travelOrdersTableColumns}
                loading={fleetApproved}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Approved Fleet Manager travel requests"
              />
              <br />
              <SubHeading title="Rejected Request" />
              <Table
                data={rejectedFleetData}
                columns={travelOrdersTableColumns}
                loading={fleetRejected}
                showBtn={false}
                showActions={true}
                onViewHandler={handleViewSummary}
                tableName="Rejected Fleet Manager travel requests"
              />
            </>
          )}

          {/*rest of the users*/}
          {
            user.designation !== "fleetManager" && (
              <>
                <SubHeading title="My Requests" />
                <Table
                  data={userData.slice().reverse()}
                  columns={travelOrdersTableColumns}
                  loading={isLoading}
                  btnLabel="Add Travel Order"
                  btnClick={onOpen}
                  showActions={true}
                  onViewHandler={handleViewSummary}
                />
              </>
            )}
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose} title="Travel Order" size="2xl">
        <TravelOrderForm
          initialValues={travelOrderInitialValues}
          validationSchema={travelOrderSchema}
          onSuccess={onClose}
          success={`Travel order added successfully`}
          useMutate={useAddTravelOrder}
        />
      </Modal>
    </>
  );
};

export default TravelOrder;
