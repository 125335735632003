import * as Yup from "yup";

export const complainantInitialValues = {
  name: "",
  sex: "",
  email: "",
  age: "",
  phoneNumber: "",
  address: "",
  country: "",
  NIN: "",
  district: "",
  county: "",
  subcounty: "",
  parish: "",
  village: "",
  marital_status: "",
  accompaniedBy: "",
  occupation: "",
  place_of_work: "",
  level_of_education: "",
  preferred_language: "",
  FIDA_clinic: "",
  project: "",
  num_beneficiaries: "",
  //   disability: "",
  //   relationship_with_respondent: "",
};

export const complainantSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sex: Yup.string().required("Sex is required"),
  email: Yup.string().email("Email is invalid"),
  age: Yup.number().max(150, "Age can not be more than 150"),
  phoneNumber: Yup.number(),
  address: Yup.string(),
  country: Yup.string().matches("^[A-Za-z\\s]*$", "Only use letters"),
  NIN: Yup.string().matches(
    "^[A-Z0-9]*$",
    "National ID can only contain numbers and capital letters"
  ),
  district: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"),
  subcounty: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"),  
  county: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"),  
  parish: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"), 
  village: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"), 
  FIDA_clinic: Yup.string().matches("^[A-Za-z\\s]*$", "Only use letters").required("This field is required"),
  project: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers").required("Project is required"),
  marital_status: Yup.string().matches("^[A-Za-z\\s]*$", "Only use letters"),
  accompaniedBy: Yup.string().matches("^[A-Za-z0-9\\s]*$", "Only use letters and numbers"),
  occupation: Yup.string().matches("^[A-Za-z\\s]*$", "Only use letters"),
  place_of_work: Yup.string().matches("^[A-Za-z\\s]*$", "Only use letters"),
  level_of_education: Yup.string().matches(
    "^[A-Za-z\\s]*$",
    "Only use letters"
  ),
  preferred_language: Yup.string().matches(
    "^[A-Za-z\\s]*$",
    "Only use letters"
  ),
});
